<template>
  <div>
    <Breadcrumbs page_title="quiz" :items="breadcrumbs" />

    <v-container>
      <v-card class="rounded-lg">
        <v-container>
          <v-toolbar height="54px" class="elevation-0">
            <v-tabs
              @change="onTabChange"
              v-model="currentTab"
              class="tab-mobileres"
              color="secondary"
            >
              <v-tabs-slider></v-tabs-slider>
              <v-tab
                class="text-capitalize"
                style="font-size: 16px; width: 150px"
                tag
                v-for="(tab, i) in Tabs"
                :key="i"
                >{{ $t(tab.title) }}</v-tab
              >
            </v-tabs>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-divider></v-divider>

          <v-tabs-items v-model="tabs" class="mt-1 pl-3">
            <v-tab-item value="tab-1">
              <v-form ref="form1" v-model="valid" lazy-validation>
                <v-row>
                  <v-col cols="12" sm="10" md="10" lg="10">
                    <v-row class="ma-1">
                      <v-col cols="12" xs="12" sm="12" md="6">
                        <label for="quizname" class="font-style">
                          {{ $t("quizname") }}
                          <span style="color: red">*</span>
                        </label>
                        <v-text-field
                          :rules="quizNameRules"
                          style="margin-top: 7px"
                          outlined
                          dense
                          v-model="quizName"
                          hide-details="auto"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <!-- Section List -->
                    <v-row class="ma-1">
                      <v-col cols="12">
                        <v-card v-for="(item, i) in section_Items" :key="i">
                          <v-row class="ma-1">
                            <v-col cols="12" xs="12" sm="12" md="6">
                              <label for="section" class="font-style">
                                {{ $t("section") }}
                                <span style="color: red">*</span>
                              </label>
                              <v-text-field
                                :rules="quizSectionRules"
                                style="margin-top: 7px"
                                outlined
                                dense
                                v-model="item.sectionName"
                                hide-details="auto"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="6">
                              <label for="description" class="font-style">{{
                                $t("description")
                              }}</label>
                              <img
                                alt
                                v-if="section_Items.length > 1"
                                src="@/assets/deletepdf.png"
                                @click="DeleteSection(item)"
                                width="20"
                                height="20"
                                style="
                                  cursor: pointer;
                                  border: 1px;
                                  margin-left: 15px;
                                  float: right;
                                  margin-top: 2px;
                                "
                              />
                              <v-text-field
                                style="margin-top: 7px"
                                outlined
                                dense
                                v-model="item.sectionDescription"
                                hide-details="auto"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <!-- Question List -->
                          <div
                            v-for="(item, i) in WhereQuestion(item.sectionId)"
                            :key="i"
                          >
                            <v-row class="ma-1 ml-5">
                              <v-col
                                cols="12"
                                md="8"
                                style="padding-right: 30px !important"
                              >
                                <v-row>
                                  <v-text-field
                                    type="text"
                                    v-model="item.question"
                                    class="font-style"
                                    :placeholder="'Question ' + ++i"
                                    single-line
                                  >
                                    <template slot="append">
                                      <v-img
                                        class="mb-3 mr-5"
                                        v-if="item.questionImge"
                                        :aspect-ratio="16 / 9"
                                        :width="170"
                                        v-bind:src="item.questionImge"
                                      ></v-img>
                                      <v-btn
                                        fab
                                        style="
                                          background-color: #ff6060;
                                          border-radius: 8px !important;
                                          color: #ffffff;
                                          box-shadow: unset;
                                          top: 35px;
                                        "
                                        x-small
                                        right
                                        absolute
                                        class="Image_Btn"
                                        @click="item.questionImge = null"
                                        v-if="item.questionImge"
                                      >
                                        <v-icon>mdi-close</v-icon>
                                      </v-btn>
                                    </template>
                                  </v-text-field>

                                  <!-- Action btn image for quiz image to upload -->
                                  <v-btn
                                    v-if="item.questionImge == null"
                                    width="20"
                                    height="30"
                                    color="white"
                                    class="smallBtn mt-4 ml-5"
                                    @click="
                                      ($refs.file.value = null),
                                        $refs.file.click(),
                                        (fileQuestionSelectquestionId =
                                          item.questionId)
                                    "
                                  >
                                    <v-img
                                      :aspect-ratio="16 / 9"
                                      :width="30"
                                      :height="30"
                                      src="@/assets/images/quiz_imagesmall.svg"
                                    ></v-img>
                                  </v-btn>
                                </v-row>
                              </v-col>

                              <!-- Answer Option type Multiple or Single answer!.... -->
                              <v-col
                                cols="12"
                                md="3"
                                :style="{
                                  'padding-top':
                                    item.questionImge != null ? '43px' : '12px',
                                }"
                              >
                                <!-- Choose Answer as Single Choice or Multiple Choice -->
                                <v-select
                                  :items="selectOptionTypes"
                                  v-model="item.multipleChoice"
                                  @input="SelectChooseAnswerType(item)"
                                  item-text="text"
                                  item-value="value"
                                  hide-no-data
                                  class="quiz-name"
                                  hide-details
                                  solo
                                  dense
                                >
                                  <template v-slot:selection="{ item }">
                                    <v-icon color="#4FB14E" class="mr-2"
                                      >mdi-checkbox-marked</v-icon
                                    >
                                    <span>{{ $t(item.text) }}</span>
                                  </template>
                                  <template v-slot:item="{ item }">
                                    <v-icon color="#4FB14E" class="mr-2"
                                      >mdi-checkbox-marked</v-icon
                                    >
                                    <span>{{ $t(item.text) }}</span>
                                  </template>
                                </v-select>
                              </v-col>

                              <!-- Scroll page up or down action arrow!......... -->
                              <v-col
                                cols="12"
                                md="1"
                                style="padding-left: 0; padding-right: 0"
                                :style="{
                                  'padding-top':
                                    item.questionImge != null ? '43px' : '15px',
                                }"
                              >
                                <v-btn
                                  @click="scrollToBottom(item)"
                                  width="22"
                                  height="30"
                                  style="
                                    color: #ffffff;
                                    background-color: #a6cc39;
                                  "
                                  class="smallBtn"
                                >
                                  <v-icon center small>mdi-arrow-down</v-icon>
                                </v-btn>

                                <v-btn
                                  @click="scrollToTop(item)"
                                  class="ml-1 smallBtn"
                                  width="22"
                                  height="30"
                                  style="
                                    color: #ffffff;
                                    background-color: #a6cc39;
                                  "
                                >
                                  <v-icon center small>mdi-arrow-up</v-icon>
                                </v-btn>
                              </v-col>
                            </v-row>
                            <v-row class="ma-1">
                              <v-col>
                                <v-card>
                                  <v-list>
                                    <!-- Answer Choice List / Option Card list-->
                                    <v-list-item-group
                                      multiple
                                      class="option_sheet"
                                    >
                                      <template
                                        v-for="(item, i) in WhereChoice(
                                          item.questionId
                                        )"
                                      >
                                        <v-list-item
                                          v-if="item"
                                          :key="item.choiceId"
                                        >
                                          <template>
                                            <v-list-item-action>
                                              <v-checkbox
                                                @click="
                                                  CheckChooseAnswerType(item)
                                                "
                                                v-model="
                                                  item.choiceAnswerMaster
                                                "
                                                color="#4FB14E"
                                              ></v-checkbox>
                                            </v-list-item-action>

                                            <v-list-item-content
                                              v-if="item"
                                              style="padding-right: 26px"
                                            >
                                              <!-- Show normal answer choices that could be image or text!..... -->
                                              <v-text-field
                                                v-if="!item.rule"
                                                :disabled="
                                                  question_Items.find(
                                                    (x) =>
                                                      x.questionId ==
                                                      item.questionId
                                                  ).showSubmitAnsBtn
                                                "
                                                type="text"
                                                @mouseover="
                                                  item.show_imgBtn = true
                                                "
                                                @mouseleave="
                                                  item.show_imgBtn = false
                                                "
                                                v-model="item.choice"
                                                class="field_Style"
                                                :placeholder="'Option ' + ++i"
                                                hide-details
                                                @blur="
                                                  check_duplicate(
                                                    item,
                                                    item.choice
                                                  )
                                                "
                                              >
                                                <template slot="append">
                                                  <v-img
                                                    v-if="item.choiceImge"
                                                    :key="item.choiceId + 1"
                                                    class="mb-3"
                                                    :aspect-ratio="16 / 9"
                                                    :width="170"
                                                    v-bind:src="item.choiceImge"
                                                  ></v-img>

                                                  <v-icon
                                                    v-if="
                                                      item.choiceAnswerMaster &&
                                                      !question_Items.find(
                                                        (x) =>
                                                          x.questionId ==
                                                          item.questionId
                                                      ).showSubmitAnsBtn
                                                    "
                                                    class="mt-1"
                                                    color="#4FB14E"
                                                    right
                                                    large
                                                    >mdi-check</v-icon
                                                  >
                                                </template>
                                              </v-text-field>

                                              <!-- For show alert when answers are duplicate!!.. -->
                                              <v-text-field
                                                v-else
                                                type="text"
                                                :disabled="
                                                  question_Items.find(
                                                    (x) =>
                                                      x.questionId ==
                                                      item.questionId
                                                  ).showSubmitAnsBtn
                                                "
                                                @mouseover="
                                                  item.show_imgBtn = true
                                                "
                                                @mouseleave="
                                                  item.show_imgBtn = false
                                                "
                                                v-model="item.choice"
                                                class="field_Style_1"
                                                :placeholder="'Option ' + ++i"
                                                hide-details
                                                @blur="
                                                  check_duplicate(
                                                    item,
                                                    item.choice
                                                  )
                                                "
                                                append-icon="mdi-alert"
                                              ></v-text-field>
                                            </v-list-item-content>

                                            <v-list-item-action v-if="item">
                                              <v-row>
                                                <v-btn
                                                  v-if="
                                                    item.choiceImge == null &&
                                                    !question_Items.find(
                                                      (x) =>
                                                        x.questionId ==
                                                        item.questionId
                                                    ).showSubmitAnsBtn
                                                  "
                                                  width="20"
                                                  height="30"
                                                  style="
                                                    padding: 0px 16px;
                                                    background-color: unset;
                                                    box-shadow: unset;
                                                    min-width: unset;
                                                  "
                                                  class="mt-1 ml-0"
                                                  @click="
                                                    ($refs.fileChoice.value =
                                                      null),
                                                      $refs.fileChoice.click(),
                                                      (fileChoiceSelectchoiceId =
                                                        item.choiceId)
                                                  "
                                                >
                                                  <v-img
                                                    :aspect-ratio="16 / 9"
                                                    :width="30"
                                                    :height="30"
                                                    src="@/assets/images/quiz_imagesmall.svg"
                                                  ></v-img>
                                                </v-btn>
                                              </v-row>
                                            </v-list-item-action>

                                            <v-list-item-action
                                              v-if="
                                                item &&
                                                WhereChoice(item.questionId)
                                                  .length > 1
                                              "
                                            >
                                              <v-icon
                                                style="
                                                  cursor: pointer;
                                                  color: #ffffff;
                                                  background-color: #ff6060;
                                                  border-radius: 8px !important;
                                                  border: 1px solid
                                                    rgba(66, 66, 66, 0.05);
                                                  width: 30px;
                                                  height: 30px;
                                                "
                                                class="mt-1"
                                                @click="Delete_Option(item)"
                                                right
                                                v-if="
                                                  !question_Items.find(
                                                    (x) =>
                                                      x.questionId ==
                                                      item.questionId
                                                  ).showSubmitAnsBtn
                                                "
                                                >mdi-close</v-icon
                                              >
                                              <v-icon
                                                v-if="
                                                  item.choiceAnswerMaster &&
                                                  question_Items.find(
                                                    (x) =>
                                                      x.questionId ==
                                                      item.questionId
                                                  ).showSubmitAnsBtn
                                                "
                                                class="mt-1"
                                                color="#A6CC39"
                                                large
                                                right
                                                >mdi-check</v-icon
                                              >
                                            </v-list-item-action>
                                          </template>
                                        </v-list-item>
                                      </template>
                                      <v-divider
                                        style="
                                          border-color: #7070707f;
                                          margin-top: 10px;
                                        "
                                      ></v-divider>
                                      <div
                                        v-if="!item.showSubmitAnsBtn"
                                        class="ml-5 mt-2"
                                      >
                                        <v-btn
                                          icon
                                          size="35"
                                          @click="Add_Option(item)"
                                          style="
                                            cursor: pointer;
                                            background-color: #4fb14e;
                                            color: #ffffff;
                                            border-radius: 8px !important;
                                            width: 25px;
                                            height: 25px;
                                          "
                                        >
                                          <v-icon color="white"
                                            >mdi-plus</v-icon
                                          >
                                        </v-btn>
                                        <span
                                          style="
                                            color: #4fb14e;
                                            font-size: 18px;
                                            cursor: pointer;
                                          "
                                          class="ml-2 mt-1"
                                          @click="Add_Option(item)"
                                          >{{ $t("addoption") }}</span
                                        >
                                      </div>
                                      <div v-else class="ml-5 mt-2">
                                        <v-row class="mt-2">
                                          <v-col>
                                            <v-row>
                                              <v-text-field
                                                solo
                                                class="limited-submit ml-4 mt-3"
                                                style="
                                                  margin-top: 7px;
                                                  max-width: 65px;
                                                "
                                                hide-spin-buttons
                                                hide-details
                                                v-model="item.questionScore"
                                                type="number"
                                              ></v-text-field>
                                              <span
                                                style="
                                                  color: #424242;
                                                  font-size: 16px;
                                                  margin-left: 10px;
                                                  margin-top: 16px;
                                                "
                                                >{{
                                                  "(" + $t("score") + ")"
                                                }}</span
                                              >
                                            </v-row>
                                          </v-col>
                                          <v-col style="text-align: right">
                                            <v-btn
                                              width="110"
                                              @click="
                                                item.showSubmitAnsBtn = false
                                              "
                                              class="text-capitalize btnStyleCancel mr-3"
                                              >{{ $t("cancel") }}</v-btn
                                            >
                                            <v-btn
                                              width="110"
                                              :key="item.questionId"
                                              v-if="item.questionId"
                                              @click="
                                                (item.showSubmitAnsBtn = false),
                                                  item.questionScore == ''
                                                    ? (item.questionScore = 0)
                                                    : item.questionScore
                                              "
                                              class="text-capitalize btnStyleSubmit mr-3 btn_hover_effect"
                                              color="#A6CC39"
                                              >{{ $t("submit") }}</v-btn
                                            >
                                          </v-col>
                                        </v-row>
                                      </div>
                                    </v-list-item-group>
                                  </v-list>
                                </v-card>
                              </v-col>
                            </v-row>
                            <v-row class="ma-1">
                              <v-col cols="12" md="6" lg="6">
                                <v-icon color="#4390DE" center
                                  >mdi-checkbox-outline</v-icon
                                >
                                <span
                                  @click="item.showSubmitAnsBtn = true"
                                  :key="item.questionId"
                                  style="
                                    color: #4390de;
                                    font-size: 18px;
                                    margin-left: 10px;
                                    cursor: pointer;
                                  "
                                  >{{ $t("answer") }}</span
                                >
                                <span
                                  style="
                                    color: #424242;
                                    font-size: 16px;
                                    margin-left: 10px;
                                  "
                                  >{{
                                    "( " +
                                    item.questionScore +
                                    " " +
                                    $t("score") +
                                    ")"
                                  }}</span
                                >
                              </v-col>
                              <v-col
                                cols="12"
                                md="6"
                                lg="6"
                                v-if="
                                  CheckedChoice(
                                    choice_Items.filter(
                                      (x) => x.questionId == item.questionId
                                    )
                                  ) == false
                                    ? (item.ans_notSelect_err =
                                        notSelectAnsErrMessage)
                                    : (item.ans_notSelect_err = false)
                                "
                                style="text-align: right !important"
                              >
                                <span
                                  style="
                                    color: red;
                                    font-size: 18px;
                                    margin-left: 10px;
                                  "
                                  >{{
                                    "Please select the correct answer."
                                  }}</span
                                >

                                <v-btn
                                  class="ml-8 text-capitalize"
                                  @click="CopyQuestion(item)"
                                  style="
                                    background-color: transparent;
                                    box-shadow: unset;
                                    cursor: pointer;
                                    color: #a6cc39;
                                    font-size: 18px;
                                  "
                                >
                                  <v-icon class="mr-2">mdi-content-copy</v-icon>
                                  {{ $t("copy") }}
                                </v-btn>

                                <v-btn
                                  class="ml-8 text-capitalize"
                                  @click="DeleteQuestion(item)"
                                  style="
                                    background-color: transparent;
                                    box-shadow: unset;
                                    cursor: pointer;
                                    color: #ff6060;
                                    font-size: 18px;
                                  "
                                >
                                  <v-icon class="mr-2"
                                    >mdi-delete-outline</v-icon
                                  >
                                  {{ $t("delete") }}
                                </v-btn>
                              </v-col>
                              <v-col
                                v-else
                                cols="12"
                                md="6"
                                lg="6"
                                style="text-align: right !important"
                              >
                                <v-btn
                                  class="ml-8 text-capitalize"
                                  @click="CopyQuestion(item)"
                                  style="
                                    background-color: transparent;
                                    box-shadow: unset;
                                    cursor: pointer;
                                    color: #a6cc39;
                                    font-size: 18px;
                                  "
                                >
                                  <v-icon class="mr-2">mdi-content-copy</v-icon>
                                  {{ $t("copy") }}
                                </v-btn>

                                <v-btn
                                  class="ml-8 text-capitalize"
                                  @click="DeleteQuestion(item)"
                                  style="
                                    background-color: transparent;
                                    box-shadow: unset;
                                    cursor: pointer;
                                    color: #ff6060;
                                    font-size: 18px;
                                  "
                                >
                                  <v-icon class="mr-2"
                                    >mdi-delete-outline</v-icon
                                  >
                                  {{ $t("delete") }}
                                </v-btn>
                              </v-col>
                            </v-row>
                          </div>
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-row class="ma-1">
                      <v-col cols="12" style="text-align: center">
                        <v-hover v-slot="{ hover }">
                          <v-btn
                            style="
                              font-size: 18px;
                              border-radius: 8px;
                              color: #a6cc39;
                              box-shadow: unset;
                              background-color: #ffffff;
                            "
                            :style="{
                              border: hover
                                ? '1px solid #A6CC39'
                                : '1px solid #C0C0C0',
                            }"
                            class="mr-5 text-capitalize"
                            @click="AddSectionFromBtn"
                          >
                            <v-icon color="#A6CC39">mdi-plus</v-icon>
                            {{ $t("addsection") }}
                          </v-btn>
                        </v-hover>
                        <v-hover v-slot="{ hover }">
                          <v-btn
                            style="
                              font-size: 18px;
                              border-radius: 8px;
                              color: #a6cc39;
                              box-shadow: unset;
                              background-color: #ffffff;
                            "
                            :style="{
                              border: hover
                                ? '1px solid #A6CC39'
                                : '1px solid #C0C0C0',
                            }"
                            class="ml-5 text-capitalize"
                            @click="AddQuestion()"
                          >
                            <v-icon color="#A6CC39">mdi-plus</v-icon>
                            {{ $t("addquestion") }}
                          </v-btn>
                        </v-hover>
                      </v-col>
                    </v-row>
                    <v-row class="ma-1">
                      <v-col style="text-align: right">
                        <v-btn
                          width="135"
                          class="text-capitalize btnStyle btn_hover_effect"
                          color="#a6cc39"
                          @click="SaveQuiz()"
                          >{{ $t("save") }}</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-form>
            </v-tab-item>

            <v-tab-item value="tab-2">
              <v-row class="ma-1 mt-4">
                <v-col>
                  <div
                    style="
                      width: 513px;
                      background: transparent;
                      border: 1px solid #c0c0c0;
                      border-radius: 8px;
                      height: 40px;
                      box-shadow: unset;
                      margin-top: 10px;
                    "
                  >
                    <p
                      style="
                        font-size: 18px;
                        margin-top: 5px;
                        margin-left: 17px;
                        color: #707070;
                      "
                    >
                      {{ quizName }}
                    </p>
                  </div>
                </v-col>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-col>
                  <div class="mr-5">
                    <v-btn
                      outlined
                      depressed
                      @click="DeleteCourse()"
                      style="
                        font-size: 16px;
                        border: 1px solid #c0c0c0;
                        color: #ffffff !important;
                        background-color: #a6cc39;
                      "
                      class="mt-3 mr-5 text-capitalize text--white rounded-lg"
                    >
                      {{ "Go to Course menu" }}
                    </v-btn>
                  </div>
                </v-col>
              </v-row>

              <div class="ma-3 mt-2">
                <!-- Add Quiz in Add Assignment -->
                <v-data-table
                  :loading="loading"
                  :loading-text="$t('loadingpleasewait')"
                  :headers="Courseheaders"
                  :items="courseOfQuiz"
                  hide-default-footer
                  class="editquiz-table"
                >
                </v-data-table>
                <v-divider
                  style="border-color: #7070707f; margin-top: 20px"
                ></v-divider>
              </div>

              <v-row>
                <v-col cols="12" sm="10" md="10" lg="10">
                  <v-row class="ma-1" v-if="quizshowingflag">
                    <v-col cols="12">
                      <p
                        style="
                          font-size: 18px;
                          margin-top: 0px;
                          margin-left: 8px;
                          margin-bottom: 0px;
                          color: #424242;
                        "
                      >
                        {{ $t("quizshowing") }}
                      </p>
                    </v-col>
                    <v-col cols="12" class="pt-1">
                      <v-radio-group
                        hide-details
                        class="mt-0 pt-0"
                        v-model="radioGroup"
                      >
                        <v-radio
                          v-for="n in radioItems"
                          :key="n.text"
                          :label="$t(n.text)"
                          :value="n.value"
                          color="#4FB14E"
                          style="
                            padding-left: 5px;
                            font-size: 14px;
                            height: 32px;
                          "
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <v-row class="ma-1">
                    <v-col cols="12">
                      <p
                        style="
                          font-size: 18px;
                          margin-top: 0px;
                          margin-left: 8px;
                          margin-bottom: 0px;
                          color: #424242;
                        "
                      >
                        {{ $t("quizsetting") }}
                      </p>
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="py-3 pl-4">
                    <v-col cols="12" md="6" class="d-flex align-center">
                      <p class="settingTabSameText">
                        {{ $t("randomquestionsandchoices") }}
                      </p>
                    </v-col>
                    <v-col cols="12" md="6" class="d-flex align-center">
                      <div class="onoffswitch ml-5">
                        <input
                          type="checkbox"
                          name="onoffswitch"
                          class="onoffswitch-checkbox"
                          id="randomonoffswitch"
                          v-model="setting.randomQuestionChoice"
                        />
                        <label
                          class="onoffswitch-label"
                          for="randomonoffswitch"
                        >
                          <div class="onoffswitch-inner"></div>
                          <div class="onoffswitch-switch"></div>
                        </label>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="py-3 pl-4">
                    <v-col cols="12" md="6" class="d-flex align-center">
                      <p class="settingTabSameText">
                        {{ $t("showanswerresultsonmobilecorrectwrong") }}
                      </p>
                    </v-col>
                    <v-col cols="12" md="6" class="d-flex align-center">
                      <div class="onoffswitch ml-5">
                        <input
                          type="checkbox"
                          name="onoffswitch"
                          class="onoffswitch-checkbox"
                          id="myonoffswitch1"
                          @input="
                            Show_correctAns_switch(
                              setting.showCorrectAnswerAfterCompletion
                            )
                          "
                          v-model="setting.showCorrectAnswerAfterCompletion"
                        />
                        <label class="onoffswitch-label" for="myonoffswitch1">
                          <div class="onoffswitch-inner"></div>
                          <div class="onoffswitch-switch"></div>
                        </label>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="py-3 pl-4">
                    <v-col cols="12" md="6" class="d-flex align-center">
                      <p class="settingTabSameText">
                        {{ $t("showcorrectanswerincaseofwronganswer") }}
                      </p>
                    </v-col>
                    <v-col cols="12" md="6" class="d-flex align-center">
                      <div class="onoffswitch ml-5">
                        <input
                          type="checkbox"
                          name="onoffswitch"
                          class="onoffswitch-checkbox"
                          id="myonoffswitch2"
                          :disabled="!setting.showCorrectAnswerAfterCompletion"
                          v-model="setting.showCorrectAnswer"
                        />
                        <label class="onoffswitch-label" for="myonoffswitch2">
                          <div class="onoffswitch-inner"></div>
                          <div class="onoffswitch-switch"></div>
                        </label>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="py-3 pl-4">
                    <v-col cols="12" md="6" class="d-flex align-center">
                      <p class="settingTabSameText">
                        {{ $t("showscore") }}
                      </p>
                    </v-col>
                    <v-col cols="12" md="6" class="d-flex align-center">
                      <div class="onoffswitch ml-5">
                        <input
                          type="checkbox"
                          name="onoffswitch"
                          class="onoffswitch-checkbox"
                          id="myonoffswitch3"
                          v-model="setting.showScore"
                        />
                        <label class="onoffswitch-label" for="myonoffswitch3">
                          <div class="onoffswitch-inner"></div>
                          <div class="onoffswitch-switch"></div>
                        </label>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="py-3 pl-4">
                    <v-col cols="12" md="6" class="d-flex align-center">
                      <p class="settingTabSameText">
                        {{ $t("showpassingscore") }}
                      </p>
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                      class="d-flex align-center"
                      style="display: flex !important"
                    >
                      <div class="onoffswitch ml-5" @click="showErrorMessage">
                        <input
                          type="checkbox"
                          name="onoffswitch"
                          class="onoffswitch-checkbox"
                          id="myonoffswitch4"
                          :disabled="setting.passingScore == 0 ? true : false"
                          v-model="setting.showPassingScore"
                        />
                        <label class="onoffswitch-label" for="myonoffswitch4">
                          <div class="onoffswitch-inner"></div>
                          <div class="onoffswitch-switch"></div>
                        </label>
                      </div>
                      <div class="mt-3 pl-4" v-if="errorMessage">
                        <span class="passingScore">{{
                          $t("specifyPassingscore")
                        }}</span>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="py-2 pl-4">
                    <v-col cols="12" md="6" class="d-flex align-end">
                      <p class="settingTabSameText">
                        {{ $t("passingscore") }}
                      </p>
                    </v-col>
                    <v-col cols="12" md="1" class="d-flex align-center">
                      <v-text-field
                        solo
                        class="ml-6 settingTextBox"
                        hide-spin-buttons
                        hide-details
                        type="number"
                        :class="{ 'red-border': showRedBorder }"
                        v-model="setting.passingScore"
                        style="width: 70px; height: 32px"
                        @input="checkPassingScore(setting.passingScore)"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="py-2 pl-4">
                    <v-col cols="12" md="6" class="d-flex align-end">
                      <p class="settingTabSameText">
                        {{ $t("limitedsubmit") }}
                      </p>
                    </v-col>
                    <v-col cols="12" md="1" class="d-flex align-center">
                      <v-text-field
                        solo
                        class="ml-6 settingTextBox"
                        hide-spin-buttons
                        hide-details
                        value="5"
                        type="number"
                        v-model="setting.limitedSubmit"
                        style="width: 70px; height: 32px"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="py-2 pl-4">
                    <v-col cols="12" md="6" class="d-flex align-end">
                      <p class="settingTabSameText">
                        {{ $t("set-timer") }}
                      </p>
                    </v-col>
                    <v-col cols="12" md="6" class="d-flex align-center">
                      <v-menu transition="slide-y-transition">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            width="70"
                            height="32"
                            v-bind="attrs"
                            v-on="on"
                            style="
                              border: 1px solid #c0c0c0;
                              opacity: 1;
                              font-size: 16px;
                              padding: 0 8px !important;
                            "
                            class="ml-6"
                            outlined
                          >
                            {{ setting.timer }}
                            <v-icon class="d-flex ml-auto"
                              >mdi-unfold-more-horizontal</v-icon
                            >
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item
                            v-for="(item, i) in timerItems"
                            :key="i"
                            @click="setting.timer = item.minute"
                            style="min-height: 32px !important"
                          >
                            <v-list-item-title class="d-flex justify-center">{{
                              item.minute
                            }}</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                      <p class="settingTabSameText">
                        {{ $t("minutes").toLowerCase() }}
                      </p>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col style="text-align: right">
                      <v-btn
                        width="135"
                        class="text-capitalize btnStyle btn_hover_effect mr-3"
                        style="
                          color: #ffff;
                          border: 1px solid #a6cc39;
                          border-radius: 8px;
                        "
                        color="#A6CC39"
                        @click="SaveSetting()"
                        >{{ $t("save") }}</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-tab-item>

            <v-tab-item value="tab-3">
              <div v-if="!view_Detail">
                <v-row class="ma-1 mt-4">
                  <div
                    style="
                      width: 513px;
                      background: transparent;
                      border: 1px solid #c0c0c0;
                      border-radius: 8px;
                      height: 40px;
                      box-shadow: unset;
                      margin-top: 10px;
                      margin-left: 10px;
                    "
                  >
                    <p
                      style="
                        font-size: 18px;
                        margin-top: 5px;
                        margin-left: 30px;
                        color: #707070;
                      "
                    >
                      {{ quizName }}
                    </p>
                  </div>
                </v-row>
                <div class="ma-3 mt-5">
                  <v-data-table
                    :loading="loading"
                    :loading-text="$t('loadingpleasewait')"
                    :headers="quiz_headers"
                    :items="quizItems"
                    hide-default-footer
                    class="editquiz-table"
                  >
                    <template v-slot:item="{ item }">
                      <tr>
                        <td class="text-xs-center">
                          <p>{{ "1" }}</p>
                        </td>
                        <td class="text-xs-center">
                          <p>{{ item.courseName }}</p>
                        </td>
                        <td class="text-xs-start">
                          <p>{{ item.startDate }}</p>
                        </td>
                        <td class="text-xs-start">
                          <p>{{ item.endDate }}</p>
                        </td>

                        <td class="text-xs-start">
                          <p>{{ item.totalUser }}</p>
                        </td>
                        <td class="text-xs-center">
                          <p style="width: 90px">{{ item.quizFinished }}</p>
                        </td>
                        <td class="text-xs-start">
                          <p style="width: 100px">{{ item.notStarted }}</p>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                  <v-divider
                    style="border-color: #7070707f; margin-top: 30px"
                  ></v-divider>
                </div>

                <p
                  style="
                    font-size: 18px;
                    margin-top: 10px;
                    margin-left: 30px;
                    color: #424242;
                  "
                >
                  {{
                    $t("insights") +
                    " ( " +
                    QuizInsights.items +
                    " " +
                    $t("Items") +
                    " / " +
                    QuizInsights.score +
                    " " +
                    $t("score") +
                    " )"
                  }}
                </p>
                <v-row class="ml-5">
                  <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="pre-fix">
                    <label
                      for="datadisplay"
                      style="
                        font-size: 16px;
                        color: #424242;
                        font-weight: bold;
                        margin-top: 5px;
                      "
                      >{{ $t("datadisplay") }}:</label
                    >
                    <v-select
                      v-model="datadisplay"
                      :items="filtereddataitems"
                      hide-details
                      item-text="name"
                      item-value="datadisplayvalue"
                      hide-no-data
                      dense
                      outlined
                      solo
                      clearable
                      multiple
                      :placeholder="'--' + $t('pleaseselect') + '--'"
                    >
                      <template v-slot:prepend-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-text-field
                              v-model="searchdata"
                              :placeholder="$t('search')"
                              autocomplete="off"
                              hide-details
                              class="searchinlist"
                              append-icon="mdi-magnify"
                            ></v-text-field>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item ripple @click="getdataSelectAll">
                          <v-list-item-action>
                            <v-icon
                              :color="datadisplay.length > 0 ? '#a6cc39' : ''"
                              >{{ icon }}</v-icon
                            >
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>Select All</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>

                      <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index === 0">
                          <span>{{ item.name }}</span>
                        </v-chip>
                        <span v-if="index === 1" class="grey--text text-caption"
                          >(+{{ datadisplay.length - 1 }} others)</span
                        >
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="pre-fix">
                    <label
                      for="group"
                      style="
                        font-size: 16px;
                        color: #424242;
                        font-weight: bold;
                        margin-top: 5px;
                      "
                      >{{ $t("group") }}:</label
                    >
                    <v-select
                      v-model="dept"
                      :items="groupOptions"
                      hide-details
                      item-text="group"
                      item-value="group"
                      hide-no-data
                      dense
                      outlined
                      solo
                      clearable
                      multiple
                      :placeholder="'--' + $t('pleaseselect') + '--'"
                    >
                      <template v-slot:prepend-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-text-field
                              v-model="searchgroup"
                              :placeholder="$t('search')"
                              autocomplete="off"
                              hide-details
                              class="searchinlist"
                              append-icon="mdi-magnify"
                            ></v-text-field>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item ripple @click="getgroupSelectAll">
                          <v-list-item-action>
                            <v-icon :color="dept.length > 0 ? '#a6cc39' : ''">{{
                              icon
                            }}</v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>Select All</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>

                      <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index === 0">
                          <span>{{ item }}</span>
                        </v-chip>
                        <span v-if="index === 1" class="grey--text text-caption"
                          >(+{{ dept.length - 1 }} others)</span
                        >
                      </template>
                    </v-select>
                  </v-col>
                </v-row>

                <!-- quiz type and change -->
                <v-row class="ml-5">
                  <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="pre-fix">
                    <label
                      for="fullnamesm"
                      style="
                        font-size: 16px;
                        color: #424242;
                        font-weight: bold;
                        margin-top: 5px;
                      "
                      >{{ $t("fullnamesm") }}:</label
                    >
                    <v-select
                      v-model="QuizUserIdSelect"
                      :items="FullNameItems"
                      item-text="fullName"
                      item-value="userId"
                      hide-no-data
                      :placeholder="'--' + $t('pleaseselect') + '--'"
                      hide-details
                      outlined
                      solo
                      dense
                      clearable
                      multiple
                    >
                      <template v-slot:prepend-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-text-field
                              v-model="searchuser"
                              :placeholder="$t('search')"
                              @input="searchInLists(), (searchtype = 'user')"
                              autocomplete="off"
                              hide-details
                              append-icon="mdi-magnify"
                            ></v-text-field>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item ripple @click="getSelectAll">
                          <v-list-item-action>
                            <v-icon
                              :color="
                                QuizUserIdSelect.length > 0 ? '#a6cc39' : ''
                              "
                              >{{ icon }}</v-icon
                            >
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>Select All</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index === 0">
                          <span>{{ item.fullName }}</span>
                        </v-chip>
                        <span v-if="index === 1" class="grey--text text-caption"
                          >(+{{ QuizUserIdSelect.length - 1 }} others)</span
                        >
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="pre-fix">
                    <label
                      for="quiztype"
                      style="
                        font-size: 16px;
                        color: #424242;
                        font-weight: bold;
                        margin-top: 5px;
                      "
                      >{{ $t("quiztype") }}</label
                    >
                    <v-select
                      v-model="quizSelectedList"
                      :items="quizTypeItems"
                      item-text="quiz_type"
                      item-value="typeId"
                      hide-no-data
                      :placeholder="'--' + $t('pleaseselect') + '--'"
                      hide-details
                      outlined
                      solo
                      dense
                      clearable
                      multiple
                    >
                      <template v-slot:prepend-item>
                        <v-list-item ripple @click="selectAllQuizType">
                          <v-list-item-action>
                            <v-icon
                              v-if="
                                quizSelectedList.length == quizTypeItems.length
                              "
                              color="#a6cc39"
                            >
                              mdi-checkbox-marked</v-icon
                            >
                            <v-icon
                              v-else
                              :color="
                                quizSelectedList.length > 0 ? '#a6cc39' : ''
                              "
                              >{{ icon }}</v-icon
                            >
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>Select All</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>

                      <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index === 0">
                          <span>{{ item.quiz_type }}</span>
                        </v-chip>
                        <span v-if="index === 1" class="grey--text text-caption"
                          >(+{{ quizSelectedList.length - 1 }} others)</span
                        >
                      </template>
                    </v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                    lg="3"
                    xl="2"
                    class="d-flex justify-space-between align-end"
                  >
                    <v-btn
                      text
                      class="text-capitalize"
                      min-width="135"
                      min-height="40"
                      style="
                        border: 1px solid #c0c0c0;
                        border-radius: 8px;
                        color: #424242;
                        font-size: 16px;
                      "
                      @click="clearAll()"
                      >{{ $t("clearall") }}</v-btn
                    >
                    <v-btn
                      min-width="135"
                      min-height="40"
                      depressed
                      class="text-capitalize btnStyle btn_hover_effect ml-4"
                      color="#A6CC39"
                      @click="GetUserQuizList()"
                      >{{ $t("search") }}</v-btn
                    >
                  </v-col>
                </v-row>
                <!-- end of quiz type change -->

                <v-row class="ml-5">
                  <v-col
                    cols="12"
                    md="6"
                    xl="6"
                    sm="12"
                    class="d-flex align-end"
                  >
                    <span style="color: red; font-size: 18px">
                      {{ $t("passingscore") + " : " }}</span
                    >
                    <span
                      v-if="setting.passingScore !== undefined"
                      style="color: red; font-size: 18px"
                    >
                      {{ setting.passingScore + " " + $t("score") }}</span
                    >
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    xl="6"
                    sm="12"
                    class="d-flex justify-end align-end"
                  >
                    <v-btn
                      @click="exportExcelInsightsTable"
                      outlined
                      depressed
                      color="#A6CC39"
                      style="
                        font-size: 16px;
                        border: 1px solid #c0c0c0;
                        border-radius: 8px;
                        height: 40px;
                      "
                      class="text-capitalize rounded-lg mr-3"
                    >
                      <v-icon>mdi-file-upload-outline</v-icon>
                      {{ $t("exportexcel") }}
                    </v-btn>
                  </v-col>
                </v-row>

                <div class="ma-3 mt-5">
                  <v-data-table
                    :loading="quizuserloading"
                    :loading-text="$t('loadingpleasewait')"
                    :headers="quiz_headers1"
                    :items="quizItems1"
                    hide-default-footer
                    class="editquiz-table"
                    @page-count="pageCount = $event"
                    :page.sync="page"
                    :items-per-page="itemsPerPage"
                  >
                    <template v-slot:item="{ item }">
                      <tr>
                        <td class="text-xs-start">{{ item.no }}</td>
                        <td class="text-xs-center">
                          <p>{{ item.fullName }}</p>
                        </td>
                        <td class="text-xs-start">
                          <p>{{ item.group }}</p>
                        </td>
                        <td class="text-xs-start">
                          <p>{{ item.courseName }}</p>
                        </td>

                        <td class="text-xs-start">
                          <p>{{ item.corrected }}</p>
                        </td>
                        <td class="text-xs-center">
                          <p style="width: 90px">{{ item.wrong }}</p>
                        </td>
                        <td class="text-xs-start">
                          <p style="width: 100px">{{ item.totalScore }}</p>
                        </td>
                        <td class="text-xs-start">
                          <p style="width: 130px">
                            {{
                              $t(
                                item.quizType
                                  .toLowerCase()
                                  .replace(/[-\/\s]/g, "")
                              )
                            }}
                          </p>
                        </td>
                        <td class="text-xs-start">
                          <v-sheet
                            v-if="setting.passingScore != 0"
                            height="20px"
                            width="65px"
                            line-height="30px"
                            :class="
                              item.passCriteriaQuiz === 'pass'
                                ? 'status_list'
                                : 'status_list1'
                            "
                          >
                            {{ $t(item.passCriteriaQuiz) }}
                          </v-sheet>
                          <div v-else style="width: 85px; padding-left: 12px">
                            {{ "-" }}
                          </div>
                        </td>
                        <td class="text-xs-start">
                          <p style="width: 100px">{{ item.finished }}</p>
                        </td>
                        <td class="text-xs-start">
                          <v-sheet
                            height="20px"
                            width="65px"
                            line-height="30px"
                            :class="
                              item.status === 'active'
                                ? 'status_list'
                                : 'status_list1'
                            "
                          >
                            {{ $t(item.status) }}
                          </v-sheet>
                        </td>
                        <td>
                          <div class="d-flex" style="margin-top: -5px">
                            <v-btn
                              color="#4FB14E"
                              text
                              style="font-size: 16px"
                              class="text-capitalize"
                              @click="viewDetail(item)"
                            >
                              <v-icon class="mr-2">mdi-eye-outline</v-icon>
                              {{ $t("view") }}
                            </v-btn>
                          </div>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </div>
                <v-row justify="space-between" class="mx-3 my-4">
                  <v-row>
                    <v-col cols="auto" class="mr-auto">
                      <v-pagination
                        circle
                        v-model="page"
                        class="pagination"
                        :length="pageCount"
                      ></v-pagination>
                    </v-col>

                    <v-col
                      cols="12"
                      lg="2"
                      md="3"
                      sm="4"
                      class="d-flex justify-end"
                    >
                      <p class="mt-3 pr-4">{{ $t("Show") }}</p>
                      <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            width="70"
                            v-bind="attrs"
                            v-on="on"
                            style="border: 1px solid #c0c0c0; opacity: 1"
                            class="text-capitalize mt-1"
                            outlined
                          >
                            {{ itemsPerPage }}
                            <v-icon>mdi-chevron-down</v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item
                            v-for="(item, index) in paginationCount"
                            :key="index"
                            @click="itemsPerPage = item.title"
                          >
                            <v-list-item-title>{{
                              item.title
                            }}</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-row>
              </div>
              <!-- Response View -->
              <div v-else>
                <v-row class="mt-1">
                  <v-col cols="12" md="4" lg="4" class="pb-0">
                    <label
                      for="assignmentcourse"
                      style="
                        font-size: 16px;
                        color: #424242;
                        font-weight: bold;
                        margin-top: 5px;
                        margin-left: 15px !important;
                      "
                      >{{ $t("assignmentcourse") }} :</label
                    >
                    <label
                      for="assignmentCourse"
                      style="
                        font-size: 16px;
                        color: #424242;
                        margin-top: 5px;
                        margin-right: 20px;
                        margin-left: 10px !important;
                      "
                      >{{ infoQuizResult.assignmentCourse }}</label
                    >
                  </v-col>
                  <v-col cols="12" md="4" lg="4" class="pb-0">
                    <label
                      for="questionsm"
                      style="
                        font-size: 16px;
                        color: #424242;
                        font-weight: bold;
                        margin-top: 5px;
                        margin-right: 10px;
                        margin-left: 30px !important;
                      "
                      >{{ $t("questionsm") }} :</label
                    >
                    <label
                      for="question"
                      style="
                        font-size: 16px;
                        color: #424242;
                        margin-top: 5px;
                        margin-right: 10px;
                        margin-left: 10px !important;
                      "
                      >{{ infoQuizResult.question }}</label
                    >
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                    lg="4"
                    class="pb-0"
                    style="text-align: right"
                  >
                    <label
                      for="Score"
                      style="
                        font-size: 16px;
                        color: #424242;
                        font-weight: bold;
                        margin-top: 5px;
                        margin-right: 10px;
                        margin-left: 30px !important;
                      "
                      >{{ $t("Total Score") }} :</label
                    >
                    <label
                      for="fullScore"
                      style="
                        font-size: 16px;
                        color: #ffff;
                        margin-top: 5px;
                        margin-right: 15px;
                        margin-left: 10px !important;
                        border: solid;
                        border-color: #67ab30;
                        background-color: #67ab30;
                      "
                    >
                      {{
                        userQuizResult.score != null ? userQuizResult.score : 0
                      }}
                      {{ " / " + userQuizResult.fullScore }}
                    </label>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="4" lg="4" class="pb-0">
                    <label
                      for="fullnamesm"
                      style="
                        font-size: 16px;
                        color: #424242;
                        font-weight: bold;
                        margin-top: 5px;
                        margin-left: 15px !important;
                      "
                      >{{ $t("fullnamesm") }} :</label
                    >
                    <label
                      for="fullName"
                      style="
                        font-size: 16px;
                        color: #424242;
                        margin-top: 5px;
                        margin-left: 5.3rem !important;
                      "
                      >{{ infoQuizResult.fullName }}</label
                    >
                  </v-col>
                  <v-col cols="12" md="4" lg="4" class="pb-0">
                    <label
                      for="Correct"
                      style="
                        font-size: 16px;
                        color: #424242;
                        font-weight: bold;
                        margin-top: 5px;
                        margin-right: 10px;
                        margin-left: 30px !important;
                      "
                      >{{ $t("Correct") }} :</label
                    >
                    <label
                      for="infoQuizResult"
                      style="
                        font-size: 16px;
                        color: #4fb14e;
                        margin-top: 5px;
                        margin-right: 20px;
                        margin-left: 10px !important;
                      "
                      >{{ infoQuizResult.corrected }}</label
                    >
                    <label
                      for="Wrong"
                      style="
                        font-size: 16px;
                        color: #424242;
                        font-weight: bold;
                        margin-top: 5px;
                        margin-right: 10px;
                        margin-left: 30px !important;
                      "
                      >{{ $t("Wrong") }} :</label
                    >
                    <label
                      for="infoQuizResult"
                      style="
                        font-size: 16px;
                        color: #ff6060;
                        margin-top: 5px;
                        margin-right: 10px;
                        margin-left: 10px !important;
                      "
                      >{{ infoQuizResult.wrong }}</label
                    >
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="pt-0">
                    <div
                      v-for="(item, i) in userQuizResult.sectionList"
                      :key="i"
                    >
                      <v-row class="ma-1">
                        <v-col cols="12" xs="12" sm="12" md="6" class="pb-0">
                          <label
                            for="section"
                            style="
                              font-size: 16px;
                              color: #424242;
                              font-weight: bold;
                              margin-right: 10px;
                            "
                            >{{ $t("section") }} :</label
                          >
                          <label
                            for="sectionName"
                            style="
                              font-size: 16px;
                              color: #424242;
                              margin-left: 10px !important;
                            "
                            >{{ item.sectionName }}</label
                          >
                        </v-col>
                      </v-row>
                      <v-row class="ma-1">
                        <v-col cols="12" xs="12" sm="12" md="6" class="pt-0">
                          <label
                            for="description"
                            style="
                              font-size: 16px;
                              color: #424242;
                              font-weight: bold;
                              margin-right: 10px;
                            "
                            >{{ $t("description") }} :</label
                          >
                          <label
                            for="sectionDescription"
                            style="
                              font-size: 16px;
                              color: #424242;
                              margin-left: 10px !important;
                            "
                            >{{ item.sectionDescription }}</label
                          >
                        </v-col>
                      </v-row>
                      <!-- Question List -->
                      <div v-for="(item, i) in item.questionList" :key="i">
                        <v-card class="ml-4" max-width="650" outlined>
                          <table>
                            <caption></caption>
                            <tr>
                              <th></th>
                            </tr>
                            <tr>
                              <td style="width: 30px"></td>
                              <td style="width: 540px; height: 10px"></td>
                              <td></td>
                            </tr>
                            <tr>
                              <td
                                v-if="checkShowCorrectAnswerHead(item) == true"
                                style="width: 40px; text-align: center"
                              >
                                <v-icon color="#FA5252"
                                  >mdi-window-close</v-icon
                                >
                              </td>
                              <td
                                v-if="checkShowCorrectAnswerHead(item) == true"
                                style="width: 540px; height: 40px"
                              >
                                <span
                                  class="ml-5"
                                  style="color: #fa5252; font-size: 20px"
                                  >{{ item.question }}</span
                                >
                              </td>

                              <td
                                v-if="checkShowCorrectAnswerHead(item) == false"
                                style="width: 40px; text-align: center"
                              >
                                <v-icon color="#67AB30">mdi-check-bold</v-icon>
                              </td>
                              <td
                                v-if="checkShowCorrectAnswerHead(item) == false"
                                style="width: 540px; height: 40px"
                              >
                                <span
                                  class="ml-5"
                                  style="color: #67ab30; font-size: 20px"
                                  >{{ item.question }}</span
                                >
                              </td>

                              <td style="width: 43px; text-align: center">
                                <span style="font-size: 20px">
                                  {{
                                    (checkShowCorrectAnswerHead(item) == true
                                      ? 0
                                      : item.questionScore) +
                                    "/" +
                                    item.questionScore
                                  }}
                                </span>
                              </td>
                            </tr>
                            <tr v-if="item.questionImge">
                              <td style="width: 40px; text-align: center"></td>
                              <td style="width: 540px; height: 40px">
                                <v-card max-width="350">
                                  <v-img
                                    :aspect-ratio="16 / 9"
                                    :width="350"
                                    v-bind:src="item.questionImge"
                                  ></v-img>
                                </v-card>
                              </td>
                              <td></td>
                            </tr>
                          </table>

                          <table
                            class="mb-2"
                            v-for="(item, i) in item.choiceList"
                            :key="i"
                          >
                            <caption></caption>
                            <tr>
                              <th></th>
                            </tr>
                            <tr>
                              <td style="width: 30px"></td>
                              <td
                                :style="[
                                  item.userAnswer == true &&
                                  item.choiceAnswerMaster == false
                                    ? {
                                        width: 'auto',
                                        height: '40px',
                                        background: '#ffd2d2',
                                      }
                                    : item.userAnswer == true &&
                                      item.choiceAnswerMaster == true
                                    ? {
                                        width: 'auto',
                                        height: '40px',
                                        background: '#a6cc394c',
                                      }
                                    : { width: 'auto', height: '40px' },
                                ]"
                              >
                                <span
                                  class="ml-5"
                                  style="color: #47484b; font-size: 20px"
                                >
                                  {{ ++i + ". " + item.choice }}
                                  <v-icon
                                    v-if="
                                      item.userAnswer == true &&
                                      item.choiceAnswerMaster == false
                                    "
                                    color="#FA5252"
                                    style="margin-left: 24.5rem"
                                    right
                                    >mdi-window-close</v-icon
                                  >
                                  <v-icon
                                    v-if="
                                      item.userAnswer == true &&
                                      item.choiceAnswerMaster == true
                                    "
                                    color="#67AB30"
                                    style="margin-left: 24.5rem"
                                    right
                                    >mdi-check-bold</v-icon
                                  >
                                </span>

                                <span v-if="item.choiceImge">
                                  <v-card max-width="350">
                                    <v-img
                                      :aspect-ratio="16 / 9"
                                      :width="350"
                                      v-bind:src="item.choiceImge"
                                    ></v-img>
                                  </v-card>
                                </span>
                              </td>
                            </tr>
                          </table>

                          <table v-if="checkShowCorrectAnswerHead(item)">
                            <caption></caption>
                            <tr>
                              <th></th>
                            </tr>
                            <tr>
                              <td style="width: 30px"></td>
                              <td style="width: 540px; height: 40px">
                                <span
                                  class="ml-5"
                                  style="color: #4fb14e; font-size: 20px"
                                  >Correct Answer</span
                                >
                              </td>
                              <td></td>
                            </tr>
                          </table>

                          <table
                            class="mb-1"
                            v-for="(item, i) in checkShowCorrectAnswerHead(
                              item
                            ) == true
                              ? item.choiceList
                              : null"
                            :key="item.choiceId"
                          >
                            <caption></caption>
                            <tr>
                              <th></th>
                            </tr>
                            <tr v-if="item.choiceAnswerMaster == true">
                              <td style="width: 30px"></td>
                              <td
                                style="
                                  background: #a6cc394c;
                                  width: 540px;
                                  height: 40px;
                                "
                              >
                                <span
                                  class="ml-5"
                                  style="color: #47484b; font-size: 20px"
                                  >{{ ++i + ". " + item.choice }}</span
                                >
                              </td>
                              <td></td>
                            </tr>
                          </table>
                        </v-card>

                        <div style="height: 20px"></div>
                      </div>
                    </div>
                  </v-col>
                </v-row>
                <v-row class="mt-0 mb-2">
                  <v-col
                    cols="12"
                    md="7"
                    style="text-align: right"
                    class="pr-0 pt-0"
                  >
                    <v-btn
                      width="135"
                      class="text-capitalize btnStyle btn_hover_effect"
                      color="#A6CC39"
                      @click="view_Detail = false"
                      >{{ $t("close") }}</v-btn
                    >
                  </v-col>
                </v-row>
              </div>
            </v-tab-item>

            <v-tab-item value="tab-5">
              <v-row>
                <v-col cols="12" md="10" sm="10" lg="10">
                  <v-row class="mt-2">
                    <v-col class="pl-6">
                      <v-row>
                        <v-col cols="2" class="pr-0">
                          <p
                            class="mb-0"
                            style="font-size: 18px; color: #424242"
                          >
                            {{ $t("Type") }}:
                          </p>
                        </v-col>
                        <v-col class="pl-0" cols="5">
                          <p
                            class="mb-0"
                            style="font-size: 18px; color: #a6cc39"
                          >
                            {{ $t("sendnotification") }}
                          </p>
                          <v-checkbox
                            class="mt-0"
                            v-model="inbox"
                            :label="$t('inbox')"
                            color="#A6CC39"
                            hide-details
                          ></v-checkbox>
                          <v-checkbox
                            v-model="pushnoti"
                            color="#A6CC39"
                            :label="$t('pushnotification')"
                            hide-details
                          ></v-checkbox>
                          <v-row no-gutters>
                            <v-col cols="2">
                              <v-checkbox
                                class="pb-5"
                                v-model="email"
                                color="#A6CC39"
                                :label="$t('email')"
                                hide-details
                                @change="handleCheckboxChange"
                              ></v-checkbox>
                            </v-col>
                            <template>
                              <v-col class="d-flex justify-center align-center">
                                <v-row
                                  class="d-flex justify-center align-center"
                                >
                                  <v-radio-group
                                    v-model="selectedRadio"
                                    class="radio-group"
                                    :disable="!email"
                                  >
                                    <v-row rows="12" class="radiobutton">
                                      <div class="radio-wrapper">
                                        <div
                                          v-if="!email"
                                          class="radio-placeholder"
                                        ></div>

                                        <v-radio
                                          v-else
                                          value="thai"
                                          class="radio-button"
                                        ></v-radio>
                                      </div>
                                      <v-img
                                        src="@/assets/thailandflag.svg"
                                        width="35"
                                        height="22"
                                        class="ml-2"
                                      ></v-img>
                                      <div class="radio-wrapper ml-2">
                                        <div
                                          v-if="!email"
                                          class="radio-placeholder"
                                        ></div>
                                        <v-radio
                                          v-else
                                          class="radio-button"
                                          value="eng"
                                        >
                                        </v-radio>
                                      </div>
                                      <v-img
                                        src="@/assets/ukflag.svg"
                                        width="35"
                                        height="22"
                                        class="ml-2"
                                      ></v-img>
                                    </v-row>
                                  </v-radio-group>
                                </v-row>
                              </v-col>
                            </template>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col>
                      <v-row>
                        <v-col cols="3" class="pr-0">
                          <p
                            class="mb-0"
                            style="font-size: 18px; color: #424242"
                          >
                            {{ $t("UserType") }}:
                          </p>
                        </v-col>
                        <v-col class="pl-0">
                          <p
                            class="mb-0"
                            style="font-size: 18px; color: #a6cc39"
                          >
                            {{ $t("assigneduser") }}
                          </p>
                          <v-checkbox
                            class="mt-0"
                            v-model="requestall"
                            :label="$t('all')"
                            color="#A6CC39"
                            hide-details
                          ></v-checkbox>
                          <v-checkbox
                            v-model="requestnotfinished"
                            color="#A6CC39"
                            :label="$t('NotStarted')"
                            hide-details
                          ></v-checkbox>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col style="text-align: right">
                      <v-btn
                        width="135"
                        class="text-capitalize btnStyle mr-3 mb-3 btn_hover_effect"
                        color="#A6CC39"
                        @click="SentNotiAssignmentCourse()"
                        >{{ $t("submit") }}</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-tab-item>

            <v-tab-item value="tab-4">
              <v-row class="ma-1 mt-4">
                <v-col>
                  <div
                    style="
                      width: 513px;
                      background: transparent;
                      border: 1px solid #c0c0c0;
                      border-radius: 8px;
                      height: 40px;
                      box-shadow: unset;
                      margin-top: 10px;
                    "
                  >
                    <p
                      style="
                        font-size: 18px;
                        margin-top: 5px;
                        margin-left: 30px;
                        color: #707070;
                      "
                    >
                      {{ quizName }}
                    </p>
                  </div>
                </v-col>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-col>
                  <v-btn
                    outlined
                    depressed
                    :disabled="certificateItems.length == 1"
                    style="
                      font-size: 16px;
                      border: 1px solid #c0c0c0;
                      color: #ffffff !important;
                      background-color: #a6cc39;
                    "
                    class="mt-5 text-capitalize text--white rounded-lg"
                    @click="OpenAddCertDialog()"
                  >
                    <v-icon class="mr-2" style="color: white !important"
                      >mdi-plus-box-outline</v-icon
                    >
                    {{ $t("addcertificate") }}
                  </v-btn>
                </v-col>
              </v-row>
              <div class="ma-3 mt-1">
                <v-data-table
                  :loading="loading"
                  :loading-text="$t('loadingpleasewait')"
                  :headers="certificate_header"
                  :items="certificateItems"
                  hide-default-footer
                  class="editquiz-table"
                >
                  <template v-slot:header.emailAutoSend="{ header }">
                    <div class="d-flex align-center">
                      {{ header.text }}
                      <div class="tooltip">
                        <v-icon
                          style="color: #363636; cursor: pointer"
                          class="mt-2 ml-2 mb-2"
                        >
                          mdi-chat-alert-outline
                        </v-icon>
                        <span class="tooltipText">
                          {{ $t("autoemailsend") }}
                        </span>
                      </div>
                    </div>
                  </template>

                  <template v-slot:item.templateImage="{ item }">
                    <v-img
                      :src="item.templateImage"
                      aspect-ratio="1"
                      contain
                      class="certTableImg"
                    >
                    </v-img>
                  </template>
                  <template v-slot:item.emailAutoSend="{ item }">
                    <div class="onoffswitch mt-3 ml-5">
                      <input
                        type="checkbox"
                        name="onoffswitch"
                        class="onoffswitch-checkbox"
                        id="randomOnOffSwitch1"
                        v-model="item.emailAutoSend"
                        @change="UpdateCertAutoMailSendStatus(item)"
                      />
                      <label class="onoffswitch-label" for="randomOnOffSwitch1">
                        <div class="onoffswitch-inner"></div>
                        <div class="onoffswitch-switch"></div>
                      </label>
                    </div>
                  </template>
                  <template v-slot:item.status="{ item }">
                    <div class="onoffswitch mt-3 ml-5">
                      <input
                        type="checkbox"
                        name="onoffswitch"
                        class="onoffswitch-checkbox"
                        id="randomOnOffSwitch2"
                        v-model="item.status"
                        @change="UpdateCertStatus(item)"
                      />
                      <label class="onoffswitch-label" for="randomOnOffSwitch2">
                        <div class="onoffswitch-inner"></div>
                        <div class="onoffswitch-switch"></div>
                      </label>
                    </div>
                  </template>
                  <template v-slot:header.autosendemail>
                    <div>
                      <v-icon class="mr-2">mdi-email</v-icon>
                      {{ $t("autosendemail") }}
                    </div>
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-btn
                      text
                      color="#4FB14E"
                      class="text-capitalize"
                      style="font-size: 14px"
                      @click="Edit_Certificate(item)"
                    >
                      <v-icon>mdi-square-edit-outline</v-icon>
                      {{ $t("edit") }}
                    </v-btn>
                    <v-btn
                      text
                      color="#FF6060"
                      style="font-size: 14px"
                      class="text-capitalize"
                      @click="Show_DeleteCertDialog(item)"
                    >
                      <v-icon>mdi-delete-outline</v-icon>
                      {{ $t("delete") }}
                    </v-btn>
                  </template>
                </v-data-table>
              </div>
              <v-divider
                style="border-color: #7070707f; margin-top: 30px"
              ></v-divider>
              <v-row class="ma-1">
                <v-col>
                  <label
                    for="listofgraduates"
                    style="
                      font-size: 24px;
                      color: #47484b;
                      margin-top: 5px;
                      margin-right: 20px;
                    "
                    >{{ $t("listofgraduates") }}</label
                  >
                </v-col>
              </v-row>

              <v-row class="ma-1 mt-4">
                <div
                  style="
                    width: 513px;
                    background: transparent;
                    border: 1px solid #c0c0c0;
                    border-radius: 8px;
                    height: 40px;
                    box-shadow: unset;
                    margin-top: 10px;
                    margin-left: 10px;
                  "
                >
                  <p
                    style="
                      font-size: 18px;
                      margin-top: 5px;
                      margin-left: 30px;
                      color: #707070;
                    "
                  >
                    {{ fullminimumscore }}
                  </p>
                </div>
              </v-row>

              <v-row class="ml-5 mb-1">
                <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="pre-fix">
                  <label
                    for="status"
                    style="
                      font-size: 16px;
                      color: #424242;
                      font-weight: bold;
                      margin-top: 5px;
                    "
                    >{{ $t("status") + $t("pass-fail") }}</label
                  >
                  <v-select
                    v-model="scoreStatusList"
                    :items="filterscoreItems"
                    hide-details
                    item-text="name"
                    item-value="name"
                    hide-no-data
                    dense
                    outlined
                    solo
                    clearable
                    multiple
                    :placeholder="'--' + $t('pleaseselect') + '--'"
                  >
                    <template v-slot:prepend-item>
                      <v-list-item>
                        <v-list-item-content>
                          <v-text-field
                            v-model="searchscoredata"
                            :placeholder="$t('search')"
                            autocomplete="off"
                            hide-details
                            class="searchinlist"
                            append-icon="mdi-magnify"
                          ></v-text-field>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item ripple @click="getscoreSelectAll">
                        <v-list-item-action>
                          <v-icon
                            v-if="
                              scoreStatusList.length == filterscoreItems.length
                            "
                            color="#a6cc39"
                          >
                            mdi-checkbox-marked</v-icon
                          >
                          <v-icon
                            v-else
                            :color="scoreStatusList.length > 0 ? '#a6cc39' : ''"
                            >{{ icon }}</v-icon
                          >
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>Select All</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>

                    <template v-slot:selection="{ item, index }">
                      <v-chip v-if="index === 0">
                        <span>{{ item.name }}</span>
                      </v-chip>
                      <span v-if="index === 1" class="grey--text text-caption"
                        >(+{{ scoreStatusList.length - 1 }} others)</span
                      >
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="pre-fix">
                  <label
                    for="group"
                    style="
                      font-size: 16px;
                      color: #424242;
                      font-weight: bold;
                      margin-top: 5px;
                    "
                    >{{ $t("group") }}</label
                  >
                  <v-select
                    v-model="dept"
                    :items="groupOptions"
                    hide-details
                    item-text="group"
                    item-value="group"
                    hide-no-data
                    solodense
                    dense
                    outlined
                    solo
                    clearable
                    multiple
                    :placeholder="'--' + $t('pleaseselect') + '--'"
                  >
                    <template v-slot:prepend-item>
                      <v-list-item>
                        <v-list-item-content>
                          <v-text-field
                            v-model="searchgroup"
                            :placeholder="$t('search')"
                            autocomplete="off"
                            hide-details
                            class="searchinlist"
                            append-icon="mdi-magnify"
                          ></v-text-field>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item ripple @click="getgroupSelectAll">
                        <v-list-item-action>
                          <v-icon :color="dept.length > 0 ? '#a6cc39' : ''">{{
                            icon
                          }}</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>Select All</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>

                    <template v-slot:selection="{ item, index }">
                      <v-chip v-if="index === 0">
                        <span>{{ item }}</span>
                      </v-chip>
                      <span v-if="index === 1" class="grey--text text-caption"
                        >(+{{ dept.length - 1 }} others)</span
                      >
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="pre-fix">
                  <label
                    for="fullnamesm"
                    style="
                      font-size: 16px;
                      color: #424242;
                      font-weight: bold;
                      margin-top: 5px;
                    "
                    >{{ $t("fullnamesm") }}</label
                  >
                  <v-select
                    v-model="QuizUserIdSelect"
                    :items="FullNameItems"
                    item-text="fullName"
                    item-value="userId"
                    hide-no-data
                    :placeholder="'--' + $t('pleaseselect') + '--'"
                    class="quiz-name"
                    hide-details
                    outlined
                    solo
                    dense
                    clearable
                    multiple
                  >
                    <template v-slot:prepend-item>
                      <v-list-item>
                        <v-list-item-content>
                          <v-text-field
                            v-model="searchuser"
                            :placeholder="$t('search')"
                            @input="searchInLists(), (searchtype = 'user')"
                            autocomplete="off"
                            hide-details
                            append-icon="mdi-magnify"
                          ></v-text-field>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item ripple @click="getSelectAll">
                        <v-list-item-action>
                          <v-icon
                            :color="
                              QuizUserIdSelect.length > 0 ? '#a6cc39' : ''
                            "
                            >{{ icon }}</v-icon
                          >
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>Select All</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                    <template v-slot:selection="{ item, index }">
                      <v-chip v-if="index === 0">
                        <span>{{ item.fullName }}</span>
                      </v-chip>
                      <span v-if="index === 1" class="grey--text text-caption"
                        >(+{{ QuizUserIdSelect.length - 1 }} others)</span
                      >
                    </template>
                  </v-select>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                  lg="2"
                  xl="2"
                  class="d-flex align-end"
                >
                  <v-btn
                    text
                    class="text-capitalize mr-3"
                    min-height="40"
                    style="
                      border: 1px solid #c0c0c0;
                      border-radius: 8px;
                      color: #424242;
                      font-size: 16px;
                    "
                    @click="clearAll_ListOfGraduate()"
                    >{{ $t("clearall") }}</v-btn
                  >
                  <v-btn
                    depressed
                    class="text-capitalize btnStyle btn_hover_effect"
                    color="#A6CC39"
                    min-width="105"
                    min-height="40"
                    @click="GetListOfGraduates()"
                    >{{ $t("search") }}</v-btn
                  >
                </v-col>
                <v-col cols="12" sm="6" md="3" xl="1" class="d-flex align-end">
                  <v-btn
                    width="135"
                    depressed
                    class="text-capitalize btnStyle btn_hover_effect"
                    color="#A6CC39"
                    :disabled="sendEmailList.length == 0"
                    @click="sendMailByList()"
                    >{{ $t("sendemail") }}</v-btn
                  >
                </v-col>
              </v-row>

              <div class="ma-3 mt-1">
                <v-data-table
                  :loading="loading"
                  :loading-text="$t('loadingpleasewait')"
                  :headers="listOfgraduate_header"
                  show-select
                  :items="listOfgraduatesItems"
                  hide-default-footer
                  class="editquiz-table"
                  v-model="sendEmailList"
                  @page-count="gratuateTablePageCount = $event"
                  :page.sync="gratuateTablePage"
                  :items-per-page="graduateItemsPerPage"
                >
                  <template v-slot:item.actions="{ item }">
                    <v-btn
                      text
                      color="#4FB14E"
                      class="text-capitalize"
                      style="font-size: 16px"
                      @click="Edit_ListOfGraduate(item)"
                    >
                      <v-icon style="font-size: 16px" class="mr-2"
                        >mdi-square-edit-outline</v-icon
                      >
                      {{ $t("edit") }}
                    </v-btn>
                    <v-btn
                      text
                      color="#424242"
                      style="font-size: 16px"
                      class="text-capitalize"
                      @click="Preview_ListOfGraduate(item)"
                    >
                      <v-icon style="font-size: 16px" class="mr-2"
                        >mdi-eye-outline</v-icon
                      >
                      {{ $t("preview") }}
                    </v-btn>
                  </template>
                  <template v-slot:item.send="{ item }">
                    <v-btn
                      color="#6fdc42"
                      class="text-capitalize"
                      style="font-size: 16px; color: #ffffff; height: 30px"
                      @click="SendCertConfirmDialog(item)"
                    >
                      <v-icon style="font-size: 16px; transform: rotate(320deg)"
                        >mdi-send</v-icon
                      >
                      {{ $t("send") }}
                    </v-btn>
                  </template>
                  <template v-slot:item.scoreStatus="{ item }">
                    <v-sheet
                      v-if="setting.passingScore != 0"
                      height="20px"
                      width="65px"
                      line-height="30px"
                      :class="
                        item.scoreStatus === 'graduate-pass'
                          ? 'status_list'
                          : 'status_list1'
                      "
                    >
                      {{ $t(item.scoreStatus) }}
                    </v-sheet>
                  </template>
                  <template v-slot:item.history="{ item }">
                    <v-btn
                      text
                      color="#A6CC39"
                      class="text-capitalize"
                      @click="ClickHistoryIcon(item)"
                    >
                      <v-icon>mdi-history</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
                <v-row justify="space-between" class="mx-3 my-4">
                  <v-row>
                    <v-col cols="auto" class="mr-auto">
                      <v-pagination
                        circle
                        v-model="gratuateTablePage"
                        class="pagination"
                        :length="gratuateTablePageCount"
                      ></v-pagination>
                    </v-col>

                    <v-col
                      cols="12"
                      lg="2"
                      md="3"
                      sm="4"
                      class="d-flex justify-end"
                    >
                      <p class="mt-3 pr-4">{{ $t("Show") }}</p>
                      <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            width="70"
                            v-bind="attrs"
                            v-on="on"
                            style="border: 1px solid #c0c0c0; opacity: 1"
                            class="text-capitalize mt-1"
                            outlined
                          >
                            {{ graduateItemsPerPage }}
                            <v-icon>mdi-chevron-down</v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item
                            v-for="(item, index) in paginationCount"
                            :key="index"
                            @click="graduateItemsPerPage = item.title"
                          >
                            <v-list-item-title>{{
                              item.title
                            }}</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-row>
              </div>
            </v-tab-item>
          </v-tabs-items>
        </v-container>
      </v-card>
    </v-container>

    <v-dialog v-model="editDialog" width="500" persistent>
      <v-card>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card-title class="secondary">
            <p style="color: #fff; font-size: 30px">{{ $t("edittext") }}</p>
          </v-card-title>
          <v-card-text>
            <v-row class="pt-5">
              <v-col cols="12">
                <label for="firstname" class="font-styleDialog">{{
                  $t("firstname")
                }}</label>
                <v-text-field
                  outlined
                  dense
                  hide-details="auto"
                  v-model="editText.firstName"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <label for="lastname" class="font-styleDialog">{{
                  $t("lastname")
                }}</label>
                <v-text-field
                  outlined
                  dense
                  hide-details="auto"
                  v-model="editText.lastName"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="mt-3 pb-5">
            <v-spacer></v-spacer>
            <v-btn
              class="text-capitalize btnfont-style btn_hover_effect"
              color="primary"
              width="100"
              @click="Save()"
              >{{ $t("save") }}</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogCrop"
      persistent
      width="1000px"
      height="800px"
      overflow="hidden"
    >
      <v-card class="rounded-lg" overflow="hidden">
        <v-card-title
          class="white--text pb-4"
          style="background-color: #a6cc39; font-size: 40px"
          >Crop Image</v-card-title
        >
        <v-card-text
          class="pt-12"
          style="height: 550px; padding-left: 4rem; padding-right: 4rem"
        >
          <vue-cropper
            ref="cropper"
            :img="option.img"
            :output-size="option.size"
            :output-type="option.outputType"
            :info="true"
            :full="option.full"
            :can-move="option.canMove"
            :can-move-box="option.canMoveBox"
            :fixed-box="option.fixedBox"
            :original="option.original"
            :auto-crop="option.autoCrop"
            :auto-crop-width="option.autoCropWidth"
            :auto-crop-height="option.autoCropHeight"
            :center-box="option.centerBox"
            :high="option.high"
          ></vue-cropper>
        </v-card-text>
        <v-card-actions class="mt-3 pb-4" style="margin-right: 50px">
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize font-style"
            text
            @click="CancelCrop()"
            width="100"
            style="
              border: 1px solid #c0c0c0;
              border-radius: 8px;
              color: #424242;
            "
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            class="text-capitalize font-style btn_hover_effect"
            width="100"
            style="color: #ffff; border: 1px solid #a6cc39; border-radius: 8px"
            color="#A6CC39"
            @click="CropImage()"
            >{{ $t("submit") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="loading" persistent width="300">
      <v-card color="#4caf50" dark>
        <v-card-text class="white--text">
          {{ $t("loadingpleasewait") }}
          <v-progress-linear
            indeterminate
            color="#ffffff"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="loadingforsentmail" persistent width="300">
      <v-card color="#4caf50" dark>
        <v-card-text class="white--text">
          {{ $t("loadingpleasewait") }}
          <v-progress-linear
            indeterminate
            color="#ffffff"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="openAddCouresDialog" width="650" scrollable persistent>
      <v-card>
        <v-card-title
          style="font-size: 20px; color: #363636; border: 1px solid #c0c0c0"
          class="pb-4"
        >
          <div>
            <v-icon
              v-if="this.courseDialogTitle == 'addcourse'"
              class="mr-1"
              style="padding-bottom: 3px"
              >mdi-plus-box-outline</v-icon
            >
            <v-icon v-else class="mr-1" style="padding-bottom: 3px"
              >mdi-square-edit-outline</v-icon
            >
            {{ $t(this.courseDialogTitle) }}
          </div>
          <v-spacer></v-spacer>
          <div>
            <v-btn
              icon
              @click="openAddCouresDialog = false"
              color="#424242"
              style="border-radius: 8px !important"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </v-card-title>
        <v-card-text style="height: 600px">
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row class="mt-1">
              <v-col cols="12" sm="12" md="6" lg="6">
                <v-text-field
                  v-model="searchCourse"
                  outlined
                  dense
                  hide-details
                  style="border-color: unset"
                  clearable
                  prepend-inner-icon="mdi-magnify"
                  :placeholder="$t('search')"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <span style="color: red">{{
                  $t("onlyonecoursecanbeselected")
                }}</span>
                <div class="mt-1">
                  <v-data-table
                    :headers="CourseListheaders"
                    :items="WhereAssignmentDropDwonForQuiz()"
                    hide-default-footer
                    class="assign-table"
                    :search="searchCourse"
                    @page-count="dialogpageCount = $event"
                    :page.sync="dialogpage"
                  >
                    <template v-slot:item="{ item }">
                      <tr>
                        <td class="text-xs-center" style="width: 40px">
                          <v-radio-group
                            v-model="CouresSelect"
                            name="rowSelector"
                            class="tableRadioBtn"
                            hide-details
                          >
                            <v-radio :value="item.id" />
                          </v-radio-group>
                        </td>

                        <td class="text-xs-start">{{ item.courseName }}</td>
                        <td class="text-xs-center">{{ item.startDateTime }}</td>

                        <td class="text-xs-center">{{ item.endDateTime }}</td>
                      </tr>
                    </template>
                  </v-data-table>
                  <v-row justify="end">
                    <div class="ma-5 d-flex">
                      <v-pagination
                        circle
                        v-model="dialogpage"
                        class="pagination"
                        :length="dialogpageCount"
                      ></v-pagination>
                    </div>
                  </v-row>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions class="mt-1 pb-5">
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize btnfont-style"
            width="100"
            style="
              border: 1px solid #c0c0c0;
              border-radius: 8px;
              color: #424242;
            "
            text
            @click="openAddCouresDialog = false"
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            width="100"
            class="text-capitalize btnfont-style btn_hover_effect"
            style="color: #ffff; border: 1px solid #a6cc39; border-radius: 8px"
            color="#A6CC39"
            @click="SelectCourseEdit()"
            >{{ $t("select") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmDialogCourse" width="587" persistent>
      <v-card>
        <v-card-title class="justify-start">
          <p style="font-size: 30px; color: #363636">
            {{ $t("deleteconfirm") }}
          </p>
        </v-card-title>

        <v-card-text>
          <p class="pt-4 text-left" style="font-size: 16px; color: #363636">
            {{ $t("suredelete") }} ?
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize mb-3"
            style="
              border: 1px solid #c0c0c0;
              border-radius: 8px;
              color: #424242;
              font-size: 20px;
            "
            width="100"
            text
            @click="confirmDialogCourse = false"
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            width="100"
            color="#FF6060"
            style="font-size: 20px; border-radius: 8px; box-shadow: unset"
            class="text-capitalize white--text mb-3"
            @click="RemoveQuizEdit()"
            >{{ $t("confirm") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteCertDialog" width="587" persistent>
      <v-card>
        <v-card-title class="justify-start">
          <p style="font-size: 30px; color: #363636">
            {{ $t("deleteconfirm") }}
          </p>
        </v-card-title>

        <v-card-text>
          <p class="pt-4 text-left" style="font-size: 16px; color: #363636">
            {{ $t("deletecertificate") }}
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize mb-3"
            style="
              border: 1px solid #c0c0c0;
              border-radius: 8px;
              color: #424242;
              font-size: 20px;
            "
            width="100"
            text
            @click="deleteCertDialog = false"
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            width="100"
            color="#FF6060"
            style="font-size: 20px; border-radius: 8px; box-shadow: unset"
            class="text-capitalize white--text mb-3"
            @click="Delete_Certificate()"
            >{{ $t("confirm") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="historyDialog" width="800" scrollable persistent>
      <v-card>
        <v-card-title style="font-size: 20px; height: 70px">
          <p>
            <v-icon class="ma-1" style="font-size: 28px; color: #a6cc39"
              >mdi-history</v-icon
            >
            {{ $t("history") }}
          </p>
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="historyDialog = false"
            color="#424242"
            style="
              border-radius: 8px !important;
              border: 1px solid rgba(66, 66, 66, 0.05);
              margin-bottom: 16px;
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-data-table
            :loading="loading"
            :loading-text="$t('loadingpleasewait')"
            :headers="history_header"
            :items="historyItems"
            hide-default-footer
            class="editquiz-table"
          >
            <template v-slot:item.templateImage="{ item }">
              <v-img
                :src="item.templateImage"
                aspect-ratio="1"
                contain
                class="certTableImg"
              >
              </v-img>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize btnfont-style btn_hover_effect"
            color="#A6CC39"
            width="80"
            @click="historyDialog = false"
            >{{ $t("close") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="sendconfirmDialog" width="500" persistent>
      <v-card>
        <v-card-title class="justify-start" v-if="!no_certificate">
          <p style="font-size: 30px; color: #363636" class="mb-0">
            {{ $t("sendconfirmation") }}
          </p>
        </v-card-title>

        <v-card-text>
          <p
            v-if="!no_certificate"
            class="pt-4 text-left"
            style="font-size: 16px; color: #363636"
          >
            {{ $t("suresendemail") }} {{ fullName }} ?
          </p>
          <p
            v-else
            class="pt-4 text-left"
            style="font-size: 16px; color: #363636"
          >
            {{ "There is no certificate data !" }}
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize mb-3"
            style="
              border: 1px solid #c0c0c0;
              border-radius: 8px;
              color: #424242;
              font-size: 16px;
            "
            width="80"
            text
            @click="sendconfirmDialog = false"
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            v-if="no_certificate"
            width="80"
            color="#A6CB3B"
            style="font-size: 16px; border-radius: 8px; box-shadow: unset"
            class="text-capitalize white--text mb-3"
            @click="sendconfirmDialog = false"
            >{{ $t("ok") }}</v-btn
          >
          <v-btn
            v-else
            width="80"
            color="#A6CB3B"
            style="font-size: 16px; border-radius: 8px; box-shadow: unset"
            class="text-capitalize white--text mb-3"
            @click="Send_CertificatePerOne()"
            >{{ $t("send") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="sendmailListDialog" width="800" scrollable persistent>
      <v-card>
        <v-card-title style="font-size: 20px; height: 70px">
          <p>
            <v-icon
              class="ma-1 mb-3"
              style="font-size: 20px; transform: rotate(320deg)"
              >mdi-send</v-icon
            >
            {{ $t("sendemail") }}
          </p>
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="Close_SendmailListDialog()"
            color="#424242"
            style="
              border-radius: 8px !important;
              border: 1px solid rgba(66, 66, 66, 0.05);
              margin-bottom: 16px;
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-data-table
            :loading="loading"
            :loading-text="$t('loadingpleasewait')"
            :headers="sentmail_header"
            :items="sendEmailList"
            hide-default-footer
            class="editquiz-table"
          >
            <template v-slot:item.delete="{ item, index }">
              <v-btn
                color="#FF6060"
                class="text-capitalize"
                style="font-size: 16px; color: #ffffff; height: 30px"
                @click="removefromSendMailList(item, index)"
              >
                {{ $t("delete") }}
              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize mb-3"
            style="
              border: 1px solid #c0c0c0;
              border-radius: 8px;
              color: #424242;
              font-size: 16px;
            "
            width="80"
            text
            @click="Close_SendmailListDialog()"
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            width="80"
            color="#A6CB3B"
            style="font-size: 16px; border-radius: 8px; box-shadow: unset"
            class="text-capitalize white--text mb-3"
            @click="Send_Certificate(sendEmailList)"
            >{{ $t("send") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <PrepareCertificate
      :show_dialog="showCertificateDialog"
      @close_dialog="(showCertificateDialog = false), GetCertificateByQuizID()"
      :q_Item="requiredDataForCert"
      :dialog_title="certificateTitle"
    />

    <EditGraduateCertificate
      v-if="showEditCertificate"
      :show_dialog="showEditCertificate"
      @close_dialog="closeEditCertificate()"
      :editData="editCertData"
    />

    <!-- preview image -->
    <v-dialog
      v-model="previewimg"
      persistent
      width="800px"
      height="800px"
      overflow="hidden"
      content-class="elevation-0 preview_class"
    >
      <v-card overflow="hidden" color="transparent">
        <v-card-title style="padding-right: 0 !important">
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="previewimg = false"
            color="#FFFFFF"
            style="border-radius: 8px !important; border: 1px solid #ffffff30"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-img
          width="800px"
          height="500px"
          contain
          style="border-radius: 8px; margin: auto"
          :src="previewImage"
        ></v-img>
      </v-card>
    </v-dialog>

    <input
      type="file"
      accept="image/*"
      class="d-none"
      ref="file"
      @change="uploadImg($event, 1, fileQuestionSelectquestionId, true)"
    />

    <input
      type="file"
      accept="image/*"
      class="d-none"
      ref="fileChoice"
      @change="uploadImg($event, 1, fileChoiceSelectchoiceId, false)"
    />
  </div>
</template>

<script>
import axios from "axios";
import { uuid } from "vue-uuid";
import { VueCropper } from "vue-cropper";
import exceljs from "exceljs";
import { saveAs } from "file-saver";
import * as moment from "moment/moment";
import PrepareCertificate from "../Certificate/PrepareCertificate.vue";
import EditGraduateCertificate from "./mini-parts/EditGraduateCertificate.vue";
import certificateJS from "../Certificate/certificate.js";

export default {
  components: {
    VueCropper,
    PrepareCertificate,
    EditGraduateCertificate,
  },
  props: {
    show: {
      type: Boolean,
      default: null,
    },
  },

  data: () => ({
    graduateItemsPerPage: 10,
    gratuateTablePageCount: 0,
    gratuateTablePage: 1,
    quizSelectedList: [0, 1],
    selectedCerti_Item: {},
    editCertData: {},
    showEditCertificate: false,
    certificateTitle: "addcertificate",
    no_certificate: false,
    //add for show prepare dialog
    requiredDataForCert: {},
    showCertificateDialog: false,
    //...end of new prepare certificate
    dept: [],
    datadisplay: [1],
    dataitems: [
      { name: "Current information", datadisplayvalue: 1 },
      { name: "Old information", datadisplayvalue: 2 },
    ],
    scoredataitems: [
      {
        name: "Pass",
      },
      {
        name: "Fail",
      },
    ],
    scoreStatusList: [],
    errorMessage: false,
    showRedBorder: false,
    totalScoreInSetting: 0, //R11
    //courseNameforExcel: "",
    Tabs: [
      { title: "questionsm" },
      { title: "setting" },
      { title: "response" },
      { title: "certification" },
      { title: "notification" },
    ],
    currentTab: 0,
    previousTab: 0,
    QuizIdLocal: localStorage.getItem("QuizId"),
    paginationCount: [{ title: 10 }, { title: 20 }, { title: 30 }],
    timerItems: [
      { minute: 0 },
      { minute: 10 },
      { minute: 20 },
      { minute: 30 },
      { minute: 40 },
      { minute: 50 },
      { minute: 60 },
      { minute: 90 },
      { minute: 120 },
    ],
    itemsPerPage: 10,
    pageCount: 0,
    page: 1,
    QuizInsights: {
      items: "",
      score: "",
    },
    showCorrectAnswer: false,
    notSelectAnsErrMessage: false,
    dialogpageCount: 0,
    dialogpage: 1,
    courseDialogTitle: null,
    courseId: null,
    confirmDialogCourse: false,
    courseOfQuiz: [],
    searchCourse: null,
    CouresSelect: null,
    openAddCouresDialog: false,
    AssignmentDropDwonForQuiz: [],
    toggle_exclusive: 0,
    uuid: uuid.v1(),
    quizId: null,
    dialogCrop: false,
    option: {
      img: "",
      size: 1,
      full: false,
      outputType: "png",
      canMove: true,
      fixedBox: true,
      original: false,
      canMoveBox: false,
      autoCrop: true,
      autoCropWidth: 800,
      autoCropHeight: 450,
      centerBox: false,
      high: true,
    },
    uploadOption: {
      id: null,
      question: null,
      fileName: null,
    },
    fileChoiceSelectchoiceId: null,
    fileQuestionSelectquestionId: null,

    QuizUserIdSelect: [],
    show_imgBtn: false,
    showImage: false,
    view_Detail: false,
    valid: true,
    editText: {
      firstName: "",
      lastName: "",
    },
    editDialog: false,
    quizName: "",
    section_Items: [],
    question_Items: [],
    //showSubmitAnsBtn: false,
    optionRadio: null,
    optionNo: 1,
    choice_Items: [
      {
        show_imgBtn: false, //show image in each row
        choiceNo: 1,
        choice: "Option",
        choiceImge: null,
        choiceAnswerMaster: null,
        userAnswer: null,
        rule: false,
      },
    ], //"Option 1", "Option 2", "", "Option 3", "Option 4"
    choice_list: 0,
    answerType: false,
    selectOptionTypes: [
      { value: false, text: "singlechoice" },
      { value: true, text: "multiplechoice" },
    ],
    FullNameItems: [], //"Junne", "Bell", "Oil"
    deptitems: [],
    quizuserloading: false,
    loading: false,
    loadingforsentmail: false,
    switch1: false,
    switch2: false,
    switch3: false,
    radioGroup: 1,
    radioItems: [
      { value: 1, text: "finishwatchingassignment" },
      { value: 2, text: "afterstartwatchingnoneedtofinishwatching" },
      { value: 3, text: "whenevernoneedtostartwatching" },
      // "finishwatchingassignment",
      // "afterstartwatchingnoneedtofinishwatching",
      // "whenevernoneedtostartwatching",
    ],
    quizshowingflag: true,
    inbox: false,
    pushnoti: false,
    selectedRadio: null,
    email: false,
    requestall: false,
    requestnotfinished: false,
    requestnotstarted: false,
    breadcrumbs: [
      {
        // image: "house.png",
        sidebar_tilte: "home",
      },
      {
        text: "quiz",
        href: "/home/quiz",
      },
      {
        text: "questionsm",
      },
    ],
    quizItems: [],
    quizItems1: [],
    quizItems2: [],
    setting: {},
    userQuizResult: {},
    infoQuizResult: {
      corrected: null,
      wrong: null,
      fullName: null,
      question: null,
      assignmentCourse: null,
    },
    dateoffset: new Date().getTimezoneOffset(),
    searchuser: null,
    searchgroup: null,
    searchdata: "",
    searchscoredata: "",
    searchtype: "user",
    userCopy: [],
    groupdata: [],
    certificateItems: [],
    listOfgraduatesItems: [],
    deleteCertDialog: false,
    certificateID: 0,
    addCertDialog: false,
    createdate: null,
    createdBy: null,
    selectFileTemplate: [
      {
        id: 0,
        templateImage: "",
        activeFlag: true,
      },
    ],
    templateItems: [],
    showEditImgTool: false,
    fileError: false,
    previewimg: false,
    previewImage: "",
    memberid: null,
    historyItems: [],
    historyDialog: false,
    fullName: null,
    graduateID: 0,
    sendconfirmDialog: false,
    sendEmailList: [],
    sendmailListDialog: false,
    //SentCertificatePerOneObject: {},
    hascertificatebyoneflag: false,
    listOfGraduateObject: {},
    emailNameList: [],
  }),

  mounted() {
    this.$store.commit("changeTab", "tab-1");
    this.GetUserListOfQuiz();
    this.GetQuizSetting();
    const auth = JSON.parse(
      localStorage.getItem("vivek_authenticated_user_data")
    );
    console.log(auth);
    console.log(localStorage.getItem("QuizId"))
    if (auth) {
      this.createdBy = auth.fullName;
      this.memberid = auth.memberId;
    }
  },
  watch: {
    file: function () {
      this.fileError = false;
    },
  },

  computed: {
    quizTypeItems() {
      return [
        { typeId: 0, quiz_type: this.$t("pretest") },
        { typeId: 1, quiz_type: this.$t("posttest") },
      ];
    },
    fullminimumscore() {
      // return this.listOfgraduatesItems.length != 0
      //   ? this.$t("full-minimum-score")
      //       .replace("{0}", this.listOfgraduatesItems[0]?.fullScore || 0)
      //       .replace("{1}", this.listOfgraduatesItems[0]?.passingScore || 0)
      //   : "";
      return this.$t("full-minimum-score")
        .replace("{0}", this.totalScoreInSetting || 0)
        .replace("{1}", this.setting?.passingScore || 0);
    },
    titleRules() {
      return [(v) => !!v || "Title is required"];
    },
    filtereddataitems() {
      const searchTerm = this.searchdata.toLowerCase();
      return this.dataitems.filter((item) =>
        item.name.toLowerCase().includes(searchTerm)
      );
    },
    filterscoreItems() {
      const searchTerm = this.searchscoredata.toLowerCase();
      return this.scoredataitems.filter((item) =>
        item.name.toLowerCase().includes(searchTerm)
      );
    },
    groupOptions() {
      const query = (this.searchgroup || "").toLowerCase();
      const uniqueGroups = [
        ...new Set(
          this.groupdata.map((obj) => (obj.group !== null ? obj.group : "null"))
        ),
      ];
      return uniqueGroups.filter((group) =>
        group.toLowerCase().includes(query)
      );
    },
    tabs: {
      get() {
        switch (this.$store.getters.tabs) {
          case "tab-1":
            this.GetQuizMasterDetail();
            break;
          case "tab-2":
            this.getAllAssignmentDropDwonForQuiz();
            this.GetQuizSetting();
            break;
          case "tab-3":
            this.GetQuizSetting();
            break;
          case "tab-4":
            this.getAllAssignmentDropDwonForQuiz(); // need course name for certificate..
            break;
          case "tab-5":
            break;
        }
        return this.$store.getters.tabs;
      },
      set() {
        this.$store.commit("changeTab", this.tabValue);
      },
    },
    quizNameRules() {
      return [(v) => !!v || "Quiz Name is required"];
    },
    quizSectionRules() {
      return [(v) => !!v || "Section is required"];
    },
    selectAll() {
      return this.QuizUserIdSelect.length === this.FullNameItems.length;
    },
    selectGroupAll() {
      return this.dept.length === this.groupdata.length;
    },
    selectdataAll() {
      return this.datadisplay.length === this.dataitems.length;
    },
    icon() {
      if (this.selectAll || this.selectGroupAll || this.selectdataAll)
        return "mdi-checkbox-marked";
      return "mdi-checkbox-blank-outline";
    },
    Courseheaders() {
      return [
        {
          text: "#",
          value: "no",
          sortable: false,
        },
        {
          text: this.$t("assignmentcourse"),
          align: "left",
          value: "courseName",
          class: "tableColor",
          sortable: false,
        },
        {
          text: this.$t("startdate"),
          align: "left",
          value: "startDateTime",
          class: "tableColor",
          sortable: false,
        },
        {
          text: this.$t("enddate"),
          align: "left",
          value: "endDateTime",
          class: "tableColor",
          sortable: false,
        },
        {
          text: this.$t("quiztype"),
          align: "left",
          value: "quizType",
          class: "tableColor",
          sortable: false,
        },
      ];
    },
    CourseListheaders() {
      return [
        {
          text: "",
          value: "part",
          class: "tableColor",
          sortable: false,
        },
        {
          text: this.$t("assignmentcourse"),
          align: "left",
          value: "courseName",
          class: "tableColor",
          sortable: true,
        },
        {
          text: this.$t("startdate"),
          align: "left",
          value: "startDate",
          class: "tableColor",
          sortable: false,
        },
        {
          text: this.$t("enddate"),
          align: "left",
          value: "endDate",
          class: "tableColor",
          sortable: false,
        },
      ];
    },
    quiz_headers() {
      return [
        {
          text: "#",
          value: "no",
          sortable: false,
        },
        {
          text: this.$t("assignmentcourse"),
          align: "left",
          value: "courseName",
          class: "tableColor",
          sortable: true,
        },
        {
          text: this.$t("startdate"),
          align: "left",
          value: "startDate",
          class: "tableColor",
          sortable: false,
        },
        {
          text: this.$t("enddate"),
          align: "left",
          value: "endDate",
          class: "tableColor",
          sortable: false,
        },
        {
          text: this.$t("totaluser"),
          align: "left",
          value: "totalUser",
          class: "tableColor",
          sortable: false,
        },
        {
          text: this.$t("quizfinished"),
          align: "left",
          value: "quizFinised",
          class: "tableColor",
          sortable: true,
        },
        {
          text: this.$t("NotStarted"),
          align: "left",
          value: "notStarted",
          sortable: true,
          class: "tableColor",
        },
      ];
    },
    quiz_headers1() {
      return [
        {
          text: "#", //this.$t("no"),
          value: "no",
          class: "tableColor",
          align: "left",
          sortable: false,
        },
        {
          text: this.$t("fullnamesm"),
          align: "left",
          value: "fullName",
          class: "tableColor",
          width: "135",
          sortable: true,
        },
        {
          text: this.$t("group"),
          align: "left",
          value: "group",
          class: "tableColor",
          sortable: true,
        },
        {
          text: this.$t("course"),
          align: "left",
          value: "courseName",
          class: "tableColor",
          width: "120",
          sortable: true,
        },
        {
          text: this.$t("corrected"),
          align: "left",
          value: "corrected",
          class: "tableColor",
          width: "120",
          sortable: true,
        },
        {
          text: this.$t("wrong"),
          align: "left",
          value: "wrong",
          class: "tableColor",
          width: "100",
          sortable: true,
        },
        {
          text: this.$t("score"),
          align: "left",
          value: "totalScore",
          class: "tableColor",
          width: "100",
          sortable: true,
        },
        {
          text: this.$t("quiztype"),
          align: "left",
          value: "quizType",
          class: "tableColor",
          sortable: true,
        },
        {
          text: this.$t("passedthecriteria"),
          align: "left",
          value: "passCriteriaQuiz",
          class: "tableColor",
          sortable: true,
        },
        {
          text: this.$t("finished"),
          align: "left",
          value: "finished",
          class: "tableColor",
          sortable: true,
        },
        {
          text: this.$t("status"),
          align: "left",
          value: "status",
          class: "tableColor",
          sortable: true,
        },
        {
          text: this.$t("Manage"),
          sortable: false,
          align: "center",
          value: "actions",
          class: "tableColor",
        },
      ];
    },
    certificate_header() {
      return [
        {
          text: this.$t("certificatebackground"),
          value: "templateImage",
          class: "tableColor",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("title"),
          align: "center",
          value: "title",
          class: "tableColor",
          sortable: false,
        },
        {
          text: this.$t("course"),
          align: "center",
          value: "courseName",
          class: "tableColor",
          sortable: false,
        },
        // {
        //   text: this.$t("emailsentformat"),
        //   align: "left",
        //   value: "emailAutoSend",
        //   class: "tableColor",
        //   sortable: false,
        // },
        {
          text: this.$t("sent"),
          align: "center",
          value: "sentUserCount",
          class: "tableColor",
          sortable: false,
        },
        {
          text: this.$t("notsentyet"),
          align: "center",
          value: "notSentUserCount",
          class: "tableColor",
          sortable: false,
        },
        {
          text: this.$t("autosendemail"),
          align: "left",
          value: "emailAutoSend",
          class: "tableColor",
          sortable: false,
        },
        {
          text: this.$t("status"),
          align: "left",
          value: "status",
          class: "tableColor",
          sortable: false,
        },
        {
          text: this.$t("Manage"),
          sortable: false,
          align: "center",
          value: "actions",
          class: "tableColor",
        },
      ];
    },
    listOfgraduate_header() {
      return [
        {
          text: this.$t("no"),
          value: "no",
          class: "tableColor",
          align: "left",
          sortable: false,
        },
        {
          text: this.$t("fullnamesm"),
          align: "left",
          value: "fullName",
          class: "tableColor",
          width: "120",
          sortable: true,
        },
        {
          text: this.$t("group"),
          align: "left",
          value: "group",
          class: "tableColor",
          sortable: true,
        },
        {
          text: this.$t("email"),
          align: "left",
          value: "email",
          class: "tableColor",
          sortable: true,
        },
        {
          text: this.$t("highest"),
          align: "left",
          value: "highestScore",
          class: "tableColor",
          width: "120",
          sortable: true,
        },
        {
          text: this.$t("lastsentdate"),
          align: "left",
          value: "lastSentDate",
          class: "tableColor",
          width: "150",
          sortable: true,
        },
        {
          text: this.$t("times"),
          align: "left",
          value: "sentCount",
          class: "tableColor",
          width: "100",
          sortable: true,
        },
        {
          text: this.$t("passedthecriteria"),
          align: "left",
          value: "scoreStatus",
          class: "tableColor",
          sortable: true,
        },
        {
          text: this.$t("Manage"),
          sortable: false,
          align: "center",
          value: "actions",
          class: "tableColor",
        },
        {
          text: this.$t("sendemail"),
          align: "left",
          value: "send",
          class: "tableColor",
          sortable: false,
        },
        {
          text: this.$t("history"),
          align: "left",
          value: "history",
          class: "tableColor",
          sortable: false,
        },
      ];
    },
    history_header() {
      return [
        {
          text: this.$t("no"),
          value: "no",
          class: "tableColor",
          align: "left",
          sortable: false,
        },
        {
          text: this.$t("certificatebackground"),
          value: "templateImage",
          class: "tableColor",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("sent-date"),
          align: "left",
          value: "date",
          class: "tableColor",
          sortable: true,
        },
        {
          text: this.$t("sent-time"),
          align: "left",
          value: "time",
          class: "tableColor",
          sortable: true,
        },
        {
          text: this.$t("send-by"),
          align: "left",
          value: "sentBy",
          class: "tableColor",
          sortable: true,
        },
      ];
    },
    sentmail_header() {
      return [
        {
          text: this.$t("no"),
          value: "no",
          class: "tableColor",
          align: "left",
          sortable: false,
        },
        {
          text: this.$t("fullnamesm"),
          align: "left",
          value: "fullName",
          class: "tableColor",
          sortable: true,
        },
        {
          text: this.$t("email"),
          align: "left",
          value: "email",
          class: "tableColor",
          sortable: true,
        },
        {
          text: "",
          align: "left",
          value: "delete",
          class: "tableColor",
          sortable: false,
        },
      ];
    },
  },

  methods: {
    handleCheckboxChange() {
      if (this.email) {
        this.selectedRadio = "thai";
      } else {
        this.selectedRadio = null;
      }
    },
    clearAll() {
      this.QuizUserIdSelect = [];
      this.dept = [];
      this.datadisplay = [1];
      this.quizSelectedList = [0, 1];
      this.GetUserQuizList();
    },
    clearAll_ListOfGraduate() {
      this.QuizUserIdSelect = [];
      this.dept = [];
      this.scoreStatusList = [];
      this.GetListOfGraduates();
    },
    showErrorMessage() {
      if (this.setting.passingScore == 0) {
        this.errorMessage = true;
        this.showRedBorder = true;
      } else {
        this.errorMessage = false;
        this.showRedBorder = false;
      }
    },
    checkPassingScore(v) {
      let self = this;
      if (/^0\d+/.test(v)) {
        alert("Please enter a valid score without leading zeros.");
        self.$nextTick(() => {
          self.setting.passingScore = 0;
          self.setting.showPassingScore = false;
        });
      } else if (v > self.totalScoreInSetting) {
        alert("Please enter the minimum score again.");
        self.$nextTick(() => {
          self.setting.passingScore = 0;
          self.setting.showPassingScore = false;
        });
      } else if (v == 0) {
        self.setting.showPassingScore = false;
        self.errorMessage = true;
      } else {
        self.errorMessage = false;
        self.showRedBorder = false;
      }
    },
    async onTabChange(clickedTab) {
      this.currentTab = clickedTab;
      await this.$nextTick();

      if (clickedTab == 0) {
        this.currentTab = 0;
        this.previousTab = 0;
        this.$store.commit("changeTab", "tab-1");
        this.breadcrumbs = [
          {
            sidebar_tilte: "home", //home
          },
          {
            text: "quiz",
            href: "/home/quiz",
          },
          {
            text: "questionsm",
          },
        ];
      } else if (clickedTab == 1) {
        this.currentTab = 1;
        this.previousTab = 1;
        this.$store.commit("changeTab", "tab-2");
        this.breadcrumbs = [
          {
            sidebar_tilte: "home", //home
          },
          {
            text: "quiz",
            href: "/home/quiz",
          },
          {
            text: "setting",
          },
        ];
      } else if (clickedTab == 2) {
        this.currentTab = 2;
        this.previousTab = 2;
        this.$store.commit("changeTab", "tab-3");
        this.GetQuizInfo();
        this.GetUserQuizList();
        this.GetQuizInsights();
        this.view_Detail = false;
        this.breadcrumbs = [
          {
            sidebar_tilte: "home", //home
          },
          {
            text: "quiz",
            href: "/home/quiz",
          },
          {
            text: "response",
          },
        ];
      } else if (clickedTab == 3) {
        this.currentTab = 3;
        this.previousTab = 3;
        this.$store.commit("changeTab", "tab-4");
        this.breadcrumbs = [
          {
            sidebar_tilte: "home", //home
          },
          {
            text: "quiz",
            href: "/home/quiz",
          },
          {
            text: "certificate",
          },
        ];
        this.GetCertificateByQuizID();
        this.GetListOfGraduates();
        //this.GetDefaultTemplate();
      } else if (clickedTab == 4) {
        //is tab-4
        if (this.courseId == null || this.courseId == undefined) {
          alert("Save Assignment Course first");
          this.currentTab = 1;
          this.$store.commit("changeTab", "tab-2");
        } else {
          this.currentTab = 4;
          this.previousTab = 4;
          this.$store.commit("changeTab", "tab-5");
          this.breadcrumbs = [
            {
              sidebar_tilte: "home", //home
            },
            {
              text: "quiz",
              href: "/home/quiz",
            },
            {
              text: "notification",
            },
          ];
        }
      }
    },
    Show_correctAns_switch(v) {
      if (v) {
        this.setting.showCorrectAnswer = false;
      }
    },
    check_duplicate(item, choice) {
      let choicetemp = this.choice_Items;
      let check =
        choicetemp.filter(
          (x) =>
            x.choice == choice &&
            choice != null &&
            x.questionId == item.questionId
        ).length > 1
          ? true
          : false;

      if (check) {
        //There is a duplicate
        //alert("There is a duplicate");
        this.choice_Items.find((x) => x.choiceId === item.choiceId).rule = true;
      } else {
        this.choice_Items.find(
          (x) => x.choiceId === item.choiceId
        ).rule = false;
      }
    },
    CheckedChoice(choices) {
      let show_errMsg = choices.find((x) => x.choiceAnswerMaster === true)
        ? true
        : false;
      return show_errMsg;
    },
    SelectChooseAnswerType(item) {
      if (item.multipleChoice === false) {
        for (let element of this.choice_Items) {
          if (element.questionId == item.questionId) {
            element.choiceAnswerMaster = false;
          }
        }
      }
    },
    CheckChooseAnswerType(item) {
      let that = this;
      that.question_Items.find(
        (x) => x.questionId == item.questionId
      ).showSubmitAnsBtn = true;
      let question_Item = this.question_Items.filter(
        (x) => x.questionId == item.questionId
      )[0];

      if (question_Item.multipleChoice === false) {
        for (let element of that.choice_Items) {
          if (
            element.questionId == item.questionId &&
            element.choiceId != item.choiceId
          ) {
            element.choiceAnswerMaster = false;
          }

          if (element.choiceId == item.choiceId) {
            element.choiceAnswerMaster = item.choiceAnswerMaster;

            if (that.notSelectAnsErrMessage === true) {
              item.ans_notSelect_err = false;
            }
          }
        }
      } else {
        if (item.choiceAnswerMaster === true) {
          if (that.notSelectAnsErrMessage === true) {
            item.ans_notSelect_err = false;
          }
        }
      }
    },
    scrollToTop(item) {
      let indexAll = [];
      for (let element of this.question_Items) {
        if (element.sectionId == item.sectionId) {
          const indexQuestionAll = this.question_Items.findIndex(
            (question_Items) => question_Items.questionId == element.questionId
          );
          indexAll.push(indexQuestionAll);
        }
      }

      if (indexAll.length > 0) {
        const indexQuestion = this.question_Items.findIndex(
          (question_Items) => question_Items.questionId == item.questionId
        );

        const indexPosition = indexAll.findIndex((x) => x == indexQuestion);

        if (indexAll[indexPosition - 1] != undefined) {
          this.question_Items.splice(indexQuestion, 1);
          this.question_Items.splice(indexAll[indexPosition - 1], 0, item);
        }
      }
    },
    scrollToBottom(item) {
      let indexAll = [];
      for (let element of this.question_Items) {
        if (element.sectionId == item.sectionId) {
          const indexQuestionAll = this.question_Items.findIndex(
            (question_Items) => question_Items.questionId == element.questionId
          );
          indexAll.push(indexQuestionAll);
        }
      }

      if (indexAll.length > 0) {
        const indexQuestion = this.question_Items.findIndex(
          (question_Items) => question_Items.questionId == item.questionId
        );

        const indexPosition = indexAll.findIndex((x) => x == indexQuestion);

        if (indexAll[indexPosition + 1] != undefined) {
          this.question_Items.splice(indexQuestion, 1);
          this.question_Items.splice(indexAll[indexPosition + 1], 0, item);
        }
      }
    },

    AddSection() {
      let section_Item = {
        sectionId: uuid.v4(),
        companyId: localStorage.getItem("companyID"),
        quizId: this.quizId,
        sectionNo: 1,
        sectionName: null,
        sectionDescription: null,
        createBy: null,
        createDate: null,
        updateBy: null,
        updateDate: null,
        activeFlag: true,
      };
      this.section_Items.push(section_Item);
    },
    AddSectionFromBtn() {
      this.AddSection();
      this.AddQuestion();
    },
    DeleteSection(item) {
      let that = this;
      const index = that.section_Items.findIndex(
        (section_Items) => section_Items.sectionId == item.sectionId
      );

      for (let i = that.choice_Items.length; i--; ) {
        if (this.choice_Items[i].sectionId === item.sectionId) {
          that.choice_Items.splice(i, 1);
        }
      }
      for (let j = that.question_Items.length; j--; ) {
        if (this.question_Items[j].sectionId === item.sectionId) {
          that.question_Items.splice(j, 1);
        }
      }
      that.section_Items.splice(index, 1);
    },
    AddQuestion() {
      let section_Item = this.section_Items[this.section_Items.length - 1];
      let questionTemp = {
        questionId: uuid.v4(),
        companyId: section_Item.companyId,
        quizId: section_Item.quizId,
        sectionId: section_Item.sectionId,
        questionNo: 1,
        multipleChoice: false,
        questionScore: 1, //5,
        question: null,
        questionImge: null,
        createBy: null,
        createDate: null,
        updateBy: null,
        updateDate: null,
        activeFlag: true,
        showSubmitAnsBtn: false,
        ans_notSelect_err: false,
      };
      this.question_Items.push(questionTemp);
      this.notSelectAnsErrMessage = false;

      let choiceTemp1 = {
        choiceId: uuid.v4(),
        companyId: questionTemp.companyId,
        quizId: questionTemp.quizId,
        sectionId: questionTemp.sectionId,
        questionId: questionTemp.questionId,
        choiceNo: 1,
        choice: null,
        choiceImge: null,
        choiceAnswerMaster: false,
        createBy: null,
        createDate: null,
        updateBy: null,
        updateDate: null,
        activeFlag: true,
        rule: false,
      };
      this.choice_Items.push(choiceTemp1);
    },
    CopyQuestion(item) {
      const index = this.question_Items.findIndex(
        (question_Items) => question_Items.questionId == item.questionId
      );

      let question_Item = this.question_Items.filter(
        (x) => x.questionId == item.questionId
      )[0];

      let nameQuizAfterCheck = null;
      if (
        question_Item?.question != null &&
        question_Item?.question != undefined &&
        question_Item?.question != ""
      ) {
        let CheckQuizName = false;
        for (let i = 1; CheckQuizName === false; i++) {
          let CheckQuizNameFrist = this.question_Items.filter(
            (x) => x.question == question_Item.question + "-copy(" + i + ")"
          )[0];
          if (CheckQuizNameFrist == null) {
            nameQuizAfterCheck = question_Item.question + "-copy(" + i + ")";
            CheckQuizName = true;
          }
        }
      } else {
        nameQuizAfterCheck = question_Item.question;
      }

      let questionTemp = {
        questionId: uuid.v4(),
        companyId: question_Item.companyId,
        quizId: question_Item.quizId,
        sectionId: question_Item.sectionId,
        questionNo: question_Item.questionNo,
        multipleChoice: question_Item.multipleChoice,
        questionScore: question_Item.questionScore,
        question: nameQuizAfterCheck,
        questionImge: question_Item.questionImge,
        createBy: this.memberid,
        createDate: question_Item.createDate,
        updateBy: this.memberid,
        updateDate: question_Item.updateDate,
        activeFlag: question_Item.activeFlag,
        showSubmitAnsBtn: question_Item.showSubmitAnsBtn,
        ans_notSelect_err: false,
      };
      this.question_Items.splice(index + 1, 0, questionTemp);

      let choice_Item = this.choice_Items.filter(
        (x) => x.questionId == item.questionId
      );
      for (let element of choice_Item) {
        let choiceTemp = {
          choiceId: uuid.v4(),
          companyId: questionTemp.companyId,
          quizId: questionTemp.quizId,
          sectionId: questionTemp.sectionId,
          questionId: questionTemp.questionId,
          choiceNo: element.choiceNo,
          choice: element.choice,
          choiceImge: element.choiceImge,
          choiceAnswerMaster: element.choiceAnswerMaster,
          createBy: element.createBy,
          createDate: element.createDate,
          updateBy: element.updateBy,
          updateDate: element.updateDate,
          activeFlag: element.activeFlag,
          rule: false,
        };

        this.choice_Items.push(choiceTemp);
      }
    },
    searchInLists() {
      if (this.searchtype == "user") {
        if (!this.searchuser) {
          this.FullNameItems = this.userCopy;
        }

        this.FullNameItems =
          this.searchuser != null
            ? this.FullNameItems.filter((x) => {
                return (
                  x.fullName
                    .toLowerCase()
                    .indexOf(this.searchuser.toLowerCase()) > -1
                );
              })
            : this.userCopy;
      } else if (this.searchtype == "group") {
        if (!this.searchgroup) {
          this.groupOptions = this.groupdata;
        }

        this.groupOptions =
          this.searchgroup != null
            ? this.groupOptions.filter((x) => {
                return (
                  x.group
                    .toLowerCase()
                    .indexOf(this.searchgroup.toLowerCase()) > -1
                );
              })
            : this.groupdata;
      }
    },
    DeleteQuestion(item) {
      let that = this;
      const index = that.question_Items.findIndex(
        (question_Items) => question_Items.questionId == item.questionId
      );
      for (let i = that.choice_Items.length; i--; ) {
        if (this.choice_Items[i].questionId === item.questionId)
          that.choice_Items.splice(i, 1);
      }
      that.question_Items.splice(index, 1);
    },
    Delete_Option(item) {
      const index = this.choice_Items.findIndex(
        (question_Items) => question_Items.choiceId == item.choiceId
      );
      this.choice_Items.splice(index, 1);
    },
    Add_Option(item) {
      let choiceTemp = {
        choiceId: uuid.v4(),
        companyId: item.companyId,
        quizId: item.quizId,
        sectionId: item.sectionId,
        questionId: item.questionId,
        choiceNo: 1,
        choice: null,
        choiceImge: null,
        choiceAnswerMaster: false,
        createBy: null,
        createDate: null,
        updateBy: null,
        updateDate: null,
        activeFlag: true,
        rule: false,
      };
      this.choice_Items.push(choiceTemp);
    },
    AddImge() {
      return "https://seedprod.blob.core.windows.net/seedpublic/seedlogo1.png";
    },

    WhereQuestion(sectionId) {
      return this.question_Items.filter((x) => x.sectionId == sectionId);
    },
    WhereChoice(questionId) {
      return this.choice_Items.filter((x) => x.questionId == questionId);
    },

    async GetQuizMasterDetail() {
      let that = this;
      if (
        localStorage.getItem("QuizId") == null ||
        localStorage.getItem("QuizId") == undefined
      ) {
        that.backToQuizPage();
      }
      that.itemsList = [];
      that.loading = true;
      let request = {
        companyID: localStorage.getItem("companyID"),
        startCreateDate: null,
        endCreateDate: null,
        quizId: that.QuizIdLocal,
      };
      await axios
        .post(`${that.web_urlV4}Quiz/GetQuizMasterDetailWeb`, request)
        .then(function (response) {
          if (response.data.status == 0) {
            that.section_Items = response.data.data.sectionList;
            that.question_Items = response.data.data.questionList.map(
              (v, index) => ({
                ...v,
                no: index + 1,
                questionScore:
                  v.questionScore == null || v.questionScore == ""
                    ? 1
                    : v.questionScore,
                showSubmitAnsBtn: false,
                ans_notSelect_err: false,
              })
            );
            that.choice_Items = response.data.data.choiceList.map(
              (v, index) => ({
                ...v,
                no: index + 1,
                rule: false,
              })
            );
            that.quizName = response.data.data.quizName;
            that.quizId = response.data.data.quizId;
            that.courseId = response.data.data.courseId;
          }
          that.totalScoreInSetting = that.question_Items
            .map((v) => v.questionScore)
            .reduce((accu, v) => parseInt(accu) + parseInt(v));
        })
        .catch(function (err) {
          that.loading = false;
          throw err;
        });
      that.loading = false;
    },

    async SaveQuiz() {
      let that = this;
      that.loading = true;
      if (that.$refs.form1.validate()) {
        let request = {
          quizId: that.quizId,
          courseId: that.courseId,
          userId: 0,
          companyId: localStorage.getItem("companyID"),
          quizName: that.quizName,
          courseName: null,
          createOrUpdateBy: this.memberid,
          sectionList: [],
        };
        for (let sectionTemp of that.section_Items) {
          let queTemp = that.question_Items.filter(
            (x) => x.sectionId == sectionTemp.sectionId
          );
          let choTemp = that.choice_Items.filter(
            (x) => x.sectionId == sectionTemp.sectionId
          );

          let sec = {
            sectionId: sectionTemp.sectionId,
            sectionNo: 0,
            sectionName: sectionTemp.sectionName,
            sectionDescription: sectionTemp.sectionDescription,
            questionList: [],
          };

          if (queTemp.length == 0) {
            alert("Please add question in section");
            that.loading = false;
            return;
          }

          for (let elementQue of queTemp) {
            let que = {
              questionId: elementQue.questionId,
              questionNo: 0,
              questionScore: parseInt(elementQue.questionScore),
              question: elementQue.question,
              questionImge: elementQue.questionImge,
              multipleChoice: elementQue.multipleChoice,
              choiceList: [],
            };

            if (
              (elementQue?.question == null ?? true) &&
              (elementQue?.questionImge == null ?? true)
            ) {
              alert("Please fill in question or add images");
              that.loading = false;
              return;
            }

            let choFilter = choTemp.filter(
              (x) => x.questionId == elementQue.questionId
            );

            let checkChoiceAnswerMaster = true;
            let checkChoice = false;
            let duplicate_option = false;

            for (let elementCho of choFilter) {
              que.choiceList.push({
                choiceId: elementCho.choiceId,
                choiceNo: 0,
                choice: elementCho.choice,
                choiceImge: elementCho.choiceImge,
                choiceAnswerMaster: elementCho.choiceAnswerMaster,
              });

              if (
                (elementCho.choice == null ||
                  elementCho.choice == undefined ||
                  elementCho.choice == "") &&
                (elementCho.choiceImge == null ||
                  elementCho.choiceImge == undefined ||
                  elementCho.choiceImge == "")
              ) {
                checkChoice = true;
              }
              if (elementCho.choiceAnswerMaster === true) {
                checkChoiceAnswerMaster = false;
                that.notSelectAnsErrMessage = false;
              }

              if (elementCho.rule) {
                duplicate_option = true;
              }
            }

            if (checkChoice) {
              alert("Please fill in choice or add images");
              that.loading = false;
              return;
            }

            if (duplicate_option) {
              alert("Duplicate options Please enter a new option.");
              that.loading = false;
              return;
            }

            if (checkChoiceAnswerMaster) {
              that.notSelectAnsErrMessage = true;
              that.loading = false;
              return;
            }

            sec.questionList.push(que);
          }

          const uniqueValues = new Set(queTemp.map((v) => v.question));

          if (uniqueValues.size < queTemp.length) {
            let ques_null = queTemp.filter(
              (x) => x.question == null || x.question == ""
            );
            let image_null = queTemp.filter((x) => x.questionImge == null);

            if (ques_null.length > 0 && image_null.length > 0) {
              // both are null
              alert("Please fill in Questions or add Images.");
              that.loading = false;
              return;
            } else if (ques_null.length == 0) {
              // no null in question
              alert("Duplicate Questions Please enter a new one.");
              that.loading = false;
              return;
            }
          }

          request.sectionList.push(sec);
        }
        await axios
          .post(`${that.web_urlV4}Quiz/EditQuizMaster`, request)
          .then(function (response) {
            if (response.data.status == 0) {
              alert("Save Success");
              that.$store.commit("changeTab", "tab-2");
              that.currentTab = 1;
            }
          })
          .catch(function (err) {
            that.loading = false;
            throw err;
          });
      }
      that.loading = false;
    },

    async GetQuizSetting() {
      let that = this;
      that.itemsList = [];
      that.loading = true;
      let request = {
        companyID: localStorage.getItem("companyID"),
        quizId: that.QuizIdLocal,
        createOrUpdateBy: that.memberid,
      };
      await axios
        .post(`${that.web_urlV4}Quiz/GetQuizSetting`, request)
        .then(function (response) {
          if (response.data.status == 0) {
            that.setting = response.data.data;

            if (that.setting.showQuizFinishWatching === true) {
              that.radioGroup = 1;
            } else if (that.setting.showQuizAfterWatching === true) {
              that.radioGroup = 2;
            } else if (that.setting.showQuizWhenever === true) {
              that.radioGroup = 3;
            }
            that.setting.passingScore =
              response.data.data.passingScore != null
                ? response.data.data.passingScore
                : 0;
            that.setting.showPassingScore =
              that.setting.passingScore == 0
                ? false
                : that.setting.showPassingScore;
          }
        })
        .catch(function (err) {
          that.loading = false;
          throw err;
        });
      that.loading = false;
    },
    async SaveSetting() {
      let that = this;
      that.loading = true;
      if (that.radioGroup == 1) {
        that.setting.showQuizFinishWatching = true;
        that.setting.showQuizAfterWatching = false;
        that.setting.showQuizWhenever = false;
      } else if (that.radioGroup == 2) {
        that.setting.showQuizFinishWatching = false;
        that.setting.showQuizAfterWatching = true;
        that.setting.showQuizWhenever = false;
      } else if (that.radioGroup == 3) {
        that.setting.showQuizFinishWatching = false;
        that.setting.showQuizAfterWatching = false;
        that.setting.showQuizWhenever = true;
      } else {
        that.setting.showQuizFinishWatching = false;
        that.setting.showQuizAfterWatching = false;
        that.setting.showQuizWhenever = false;
      }
      that.setting.limitedSubmit = parseInt(that.setting.limitedSubmit);
      that.setting.passingScore = parseInt(that.setting.passingScore);
      that.setting.timer = parseInt(that.setting.timer);
      that.setting.updateBy = that.memberid;
      that.setting.courseId = that.courseOfQuiz?.[0]?.id ?? null;

      await axios
        .post(`${that.web_urlV4}Quiz/EditQuizSetting`, that.setting)
        .then(function (response) {
          if (response.data.status == 0) {
            that.courseId = that.courseOfQuiz?.[0]?.id ?? null;
            if (that.courseId != null && that.courseId != undefined) {
              alert("Save Success both setting and Course.");
            }
            that.$store.commit("changeTab", "tab-4");
            that.currentTab = 3;
          }
        })
        .catch(function (err) {
          that.loading = false;
          throw err;
        });
      that.loading = false;
    },

    async GetUserListOfQuiz() {
      let that = this;
      that.loading = true;
      let request = {
        companyID: localStorage.getItem("companyID"),
        quizId: that.QuizIdLocal,
      };
      await axios
        .post(`${that.web_urlV4}Quiz/GetUserListOfQuiz`, request)
        .then(function (response) {
          if (response.data.status == 0) {
            that.FullNameItems = response.data.data;
            that.userCopy = response.data.data;
          }
        })
        .catch(function (err) {
          that.loading = false;
          throw err;
        });
      that.loading = false;
    },
    async GetUserQuizList() {
      let that = this;
      let displayrequestdata;
      if (that.datadisplay.length === 1) {
        displayrequestdata = that.datadisplay[0];
      } else {
        displayrequestdata = 0;
      }
      let deptdata;
      deptdata = that.dept != "null" ? this.dept : [""];
      that.quizuserloading = true;
      let request = {
        companyID: localStorage.getItem("companyID"),
        quizId: that.QuizIdLocal,
        offset: that.dateoffset,
        userIdList: that.QuizUserIdSelect,
        group: deptdata,
        displayData: displayrequestdata,
      };
      await axios
        .post(`${that.web_urlV4}Quiz/GetUserQuizList`, request)
        .then(function (response) {
          if (response.data.status == 0) {
            that.quizItems1 = response.data.data.map((v, i) => ({
              ...v,
              no: i + 1,
              status: v.status.toLowerCase(),
              passCriteriaQuiz:
                v.totalScore >= that.setting.passingScore ? "pass" : "fail",
              finished: moment(v.finished).local().format("DD/MM/YYYY  LT"),
            }));
            that.groupdata = response.data.data;

            if (that.quizSelectedList.length != 0) {
              const quizTypes = that.quizSelectedList.map((x) =>
                x == 0 ? "Pre-Test" : "Post-Test"
              );
              let temp = that.quizItems1.filter((item) =>
                quizTypes.includes(item.quizType)
              );
              that.quizItems1 = temp;
            }
          }
        })
        .catch(function (err) {
          that.quizuserloading = false;
          throw err;
        });
      that.quizuserloading = false;
    },
    async GetQuizInfo() {
      let that = this;
      let request = {
        companyID: localStorage.getItem("companyID"),
        quizId: that.QuizIdLocal,
        offset: that.dateoffset,
      };
      await axios
        .post(`${that.web_urlV4}Quiz/GetQuizInfo`, request)
        .then(function (response) {
          if (response.data.status == 0) {
            that.quizItems = response.data.data;
          }
        })
        .catch(function (err) {
          that.loading = false;
          throw err;
        });
    },

    async viewDetail(item) {
      let that = this;
      that.loading = true;

      this.infoQuizResult.corrected = item.corrected;
      this.infoQuizResult.wrong = item.wrong;
      this.infoQuizResult.fullName = item.fullName;
      this.infoQuizResult.question = item.corrected + item.wrong;
      this.infoQuizResult.assignmentCourse = item.courseName;

      that.view_Detail = true;
      that.loading = true;
      let request = {
        companyID: localStorage.getItem("companyID"),
        quizId: that.QuizIdLocal,
        userId: item.userId,
        userQuizId: item.userQuizId,
      };
      await axios
        .post(`${that.web_urlV4}Quiz/GetUserQuiz`, request)
        .then(function (response) {
          if (response.data.status == 0) {
            that.userQuizResult = response.data.data;
          }
        })
        .catch(function (err) {
          that.loading = false;
          throw err;
        });
      that.loading = false;
    },
    checkShowCorrectAnswer(item) {
      for (let element of item.choiceList) {
        if (
          element.userAnswer === true &&
          element.choiceAnswerMaster === false
        ) {
          return true;
        }
      }
      return false;
    },
    checkShowCorrectAnswerHead(item) {
      if (item.multipleChoice) {
        let choiceFilterTrue = item.choiceList.filter(
          (x) => x.choiceAnswerMaster === true
        );

        for (let choiceFilterTrueMember of choiceFilterTrue) {
          if (choiceFilterTrueMember.userAnswer !== true) {
            return true;
          }
        }
      } else {
        for (let element of item.choiceList) {
          if (
            element.userAnswer === true &&
            element.choiceAnswerMaster === false
          ) {
            return true;
          }
        }
      }
      return false;
    },

    uploadImg(e, num, id, question) {
      let self = this;
      let file = e.target.files[0];
      self.uploadOption.id = id;
      self.uploadOption.question = question;
      self.uploadOption.fileName = file.name;

      if (!/\.(gif|jpg|jpeg|png|bmp|GIF|JPG|PNG)$/.test(e.target.value)) {
        alert("Please choose image!");
        return false;
      }
      let reader = new FileReader();
      reader.onload = function (f) {
        let data;
        if (typeof f.target.result === "object") {
          data = window.URL.createObjectURL(new Blob([f.target.result]));
        } else {
          data = f.target.result;
        }
        if (num === 1) {
          self.option.img = data;
          self.dialogCrop = true;
        }
      };
      reader.readAsArrayBuffer(file);
    },
    CancelCrop() {
      this.dialogCrop = false;
    },
    CropImage() {
      this.$refs.cropper.getCropData((data) => {
        this.UploadFileStringBase(data);
        this.dialogCrop = false;
      });
    },

    async UploadFileStringBase(data) {
      let that = this;
      that.loading = true;
      let request = {
        base64: data,
        fileName: that.uploadOption.fileName,
      };
      await axios
        .post(`${that.web_url}File/UploadFileStringBaseQuiz`, request)
        .then(function (response) {
          if (that.uploadOption.question === true) {
            that.question_Items.filter(
              (x) => x.questionId == that.uploadOption.id
            )[0].questionImge = response.data;
          } else {
            that.choice_Items.filter(
              (x) => x.choiceId == that.uploadOption.id
            )[0].choiceImge = response.data;
          }
        })
        .catch(function (err) {
          that.loading = false;
          throw err;
        });
      that.loading = false;
    },
    getSelectAll() {
      if (this.QuizUserIdSelect.length == 0) {
        this.QuizUserIdSelect = this.FullNameItems.map(({ userId }) => userId);
      } else {
        this.QuizUserIdSelect = [];
      }
    },
    getdataSelectAll() {
      if (this.datadisplay.length == 0) {
        this.datadisplay = this.dataitems;
      } else {
        this.datadisplay = [];
      }
    },
    selectAllQuizType() {
      if (this.quizSelectedList.length == 0) {
        this.quizSelectedList = this.quizTypeItems.map(({ typeId }) => typeId);
      } else {
        this.quizSelectedList = [];
      }
    },
    getscoreSelectAll() {
      if (this.scoreStatusList.length == 0) {
        this.scoreStatusList = this.scoredataitems;
      } else {
        this.scoreStatusList = [];
      }
    },
    getgroupSelectAll() {
      if (this.dept.length == 0) {
        this.dept = this.groupdata.map(({ group }) => group);
      } else {
        this.dept = [];
      }
    },
    Selectfile() {
      this.$refs.fileChoice.value = null;
      this.$refs.fileChoice.click();
    },
    async exportExcelInsightsTable() {
      this.exportDialog = false;
      const headers = [
        "NO",
        "FULL NAME",
        "GROUP",
        "ASSIGNMENT COURSE",
        "CORRECTED",
        "WRONG",
        "SCORE",
        "QUIZ TYPE",
        "STATUS",
        "FINISHED",
      ];
      const keys = [
        "no",
        "fullName",
        "group",
        "courseName",
        "corrected",
        "wrong",
        "totalScore",
        "quizType",
        "status",
        "finished",
      ];
      const fileName = `${"Quiz Report"}.xlsx`;
      const filterItems = this.quizItems1.map((x) => ({ ...x }));
      const data = [];
      data.push(headers);
      for (const item of filterItems) {
        let row = [];

        for (const key of keys) {
          row.push(item[key] || 0);
        }
        data.push(row);
      }
      let workbook2 = new exceljs.Workbook();

      workbook2.addWorksheet("Quiz Report", {
        views: [{ showGridLines: true }],
      });
      let worksheet2 = workbook2.getWorksheet(1);

      data.forEach((element) => {
        worksheet2.addRow(element);
      });
      worksheet2.getRow(1).eachCell(function (cell) {
        //number
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "ff9900" },
          bgColor: { argb: "ff9900" },
        };
        cell.font = {
          name: "Calibri",
          size: 11,
          color: { argb: "000000" },
          bold: true,
        };
        cell.alignment = { vertical: "middle", horizontal: "center" };
      });
      worksheet2.getRows(1, worksheet2.actualRowCount - 1).forEach((x) =>
        x.eachCell(function (cell) {
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
          cell.alignment = { vertical: "middle", horizontal: "center" };
        })
      );
      worksheet2.lastRow.eachCell(function (cell) {
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
        cell.alignment = { vertical: "middle", horizontal: "center" };
      });
      worksheet2.columns.forEach((column, i) => {
        column.width = headers[i].length + (i <= 10 ? 15 : 19);
      });
      workbook2.xlsx.writeBuffer().then((val) => {
        let blob = new Blob([val], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
        });
        saveAs(blob, fileName);
      });
    },

    backToQuizPage() {
      this.$router.push("/home/quiz");
    },
    async getAllAssignmentDropDwonForQuiz() {
      let that = this;

      await axios
        .get(
          `${that.web_url}Assignment/getAllAssignmentDropDwonForQuizID?Quizid=` +
            that.QuizIdLocal
        )
        .then(function (response) {
          if (response.data.status == 0) {
            that.courseOfQuiz = response.data.data.map((v) => ({
              ...v,
              startDateTime: moment(v.startDateTime)
                .local()
                .format("DD/MM/YYYY"),
              endDateTime: moment(v.endDateTime).local().format("DD/MM/YYYY"),
            }));

            if (that.courseOfQuiz != []) {
              if (that.courseOfQuiz[0]?.quizType == "Pre-Test") {
                that.quizshowingflag = false;
              }
            }
          }
        })
        .catch(function (err) {
          that.loading = false;
          throw err;
        });
    },
    OpenCourseDialog(value) {
      let that = this;
      that.CouresSelect = that.courseOfQuiz?.[0]?.id ?? null;
      if (value == 1) {
        that.courseDialogTitle = "addcourse";
        that.openAddCouresDialog = true;
      } else {
        that.courseDialogTitle = "editcourse";
        that.openAddCouresDialog = true;
      }
    },
    WhereAssignmentDropDwonForQuiz() {
      return this.AssignmentDropDwonForQuiz.filter(
        (x) => x.quizId == null || x.quizId == this.quizId
      );
    },
    SelectCourseEdit() {
      this.courseOfQuiz = [];
      let selectedTemp = this.AssignmentDropDwonForQuiz.filter(
        (x) => x.id == this.CouresSelect
      )?.[0];
      if (selectedTemp != undefined) {
        this.courseOfQuiz.push(selectedTemp);
      }
      this.openAddCouresDialog = false;
    },
    RemoveQuizEdit() {
      this.courseOfQuiz = [];
      this.CouresSelect = null;
      this.confirmDialogCourse = false;
    },
    DeleteCourse() {
      this.$router.push("/home/assignment-course");
    },
    async checkFlow(item) {
      if (item == "tab-4") {
        if (this.courseId == null || this.courseId == undefined) {
          if (this.toggle_exclusive == 3) {
            alert("Save Assignment Course frist");
          }
          this.$store.commit("changeTab", "tab-2");
          this.toggle_exclusive = 1;
        }
      }
    },
    async SentNotiAssignmentCourse() {
      let that = this;
      let course_saved = that.courseOfQuiz?.[0]?.id ?? null;
      if (course_saved == null && course_saved == undefined) {
        alert("Please select a course before sending this notification.");
        return;
      }

      if (
        that.inbox === false &&
        that.pushnoti === false &&
        that.email === false
      ) {
        alert("Please select the Send Notification type.");
        return;
      }

      if (
        that.requestall === false &&
        that.requestnotfinished === false &&
        that.requestnotstarted === false
      ) {
        alert("Please select Assigned User for sending notifications.");
        return;
      }

      that.loading = true;
      let request = {
        companyID: localStorage.getItem("companyID"),
        quizId: that.quizId,
        Inbox: that.inbox,
        PushNotification: that.pushnoti,
        Email: that.email,
        emailLanguage: that.selectedRadio,
        AssignedUserAll: that.requestall,
        AssignedUserNotFinished: that.requestnotfinished,
        AssignedUserNotStarted: that.requestnotstarted,
      };

      await axios
        .post(
          `${that.web_url}SendNotification/SentNotiFromUserAssignmentCourseOfQuiz`,
          request
        )
        .then(function (response) {
          if (response.data.status == 0) {
            alert("Send complete.");
          }
        })
        .catch(function (err) {
          that.loading = false;
          throw err;
        });
      that.loading = false;
      that.backToQuizPage(); //go back to main quiz table page
    },
    async GetQuizInsights() {
      let that = this;

      let request = {
        companyID: localStorage.getItem("companyID"),
        quizId: that.QuizIdLocal,
      };
      await axios
        .post(`${that.web_urlV4}Quiz/GetQuizInsights`, request)
        .then(function (response) {
          if (response.data.status == 0) {
            that.QuizInsights = response.data.data;
          }
        })
        .catch(function (err) {
          throw err;
        });
    },
    async GetCertificateByQuizID() {
      let that = this;
      await axios
        .get(
          `${that.web_url}Certificate/GetCertificateByQuizID?QuizID=` +
            that.QuizIdLocal
        )
        .then(function (response) {
          if (response.data.status === 0 && response.data.data.length != 0) {
            that.certificateItems = response.data.data.map((v) => ({
              ...v,
              emailAutoSend: v.emailAutoSend,
            }));
          } else {
            that.certificateItems = [];
          }
        });
    },
    async GetListOfGraduates() {
      let that = this;
      let deptdata;
      deptdata = that.dept != "null" ? this.dept : [];

      let statusdata;
      if (that.scoreStatusList.length === 1) {
        statusdata = that.scoreStatusList[0].toLowerCase();
      }
      let request = {
        quizID: that.QuizIdLocal,
        companyID: localStorage.getItem("companyID"),
        status: statusdata,
        group: deptdata,
        userID: that.QuizUserIdSelect,
      };
      await axios
        .post(`${that.web_url}Certificate/GetListOfGraduatesByQuizID`, request)
        .then(function (response) {
          if (response.data.status == 0) {
            that.listOfgraduatesItems = response.data.data.map((v, i) => ({
              ...v,
              no: i + 1,
              fullName: v.firstName + " " + v.lastName,
              lastSentDate:
                v.lastSentDate != null
                  ? moment(v.lastSentDate).local().format("DD/MM/YYYY  LT")
                  : "-",
              scoreStatus:
                v.scoreStatus === "pass" ? "graduate-pass" : "graduate-fail",
            }));
            that.groupdata = response.data.data;
          }
        });
    },

    async getEditCertDataOfUser(item) {
      let that = this;
      that.loading = true;
      try {
        const response = await axios.get(
          `${that.web_url}Certificate/GetCertificatePerUserByQuizID?QuizID=` +
            item.quizID +
            `&UserID=` +
            item.userID
        );

        if (response.data.status === 0) {
          that.loading = false;
          return response.data.data;
        } else {
          that.loading = false;
          that.showEditCertificate = false;
          alert("Something went wrong.");
          return null; // Return null if there's an error
        }
      } catch (error) {
        that.loading = false;
        that.showEditCertificate = false;
        console.error(error);
        return null; // Return null in case of error
      }
    },

    async Edit_ListOfGraduate(item) {
      // check user already have own updated templates
      let that = this;
      try {
        const temp = await that.getEditCertDataOfUser(item);

        if (
          temp &&
          temp.certificatePerUserID == null &&
          that.certificateItems[0]?.title == null
        ) {
          that.no_certificate = true;
          that.sendconfirmDialog = true;
        } else {
          that.editCertData = {
            certiData:
              temp.certificatePerUserID == null
                ? that.certificateItems[0]
                : temp,
            fullName: item.fullName,
            userID: item.userID,
            createdBy: that.memberid,
          };
          that.showEditCertificate = true;
        }
      } catch (error) {
        console.error(error);
      }
    },

    closeEditCertificate() {
      this.showEditCertificate = false;
      this.GetCertificateByQuizID();
      this.editCertData = {};
      this.GetListOfGraduates();
    },

    async Preview_ListOfGraduate(item) {
      let that = this;
      let certi_Item = this.certificateItems[0] ?? null;
      try {
        const temp = await that.getEditCertDataOfUser(item);

        if (
          temp?.certificatePerUserID === null &&
          that.certificateItems.length === 0
        ) {
          //no certificate data...
          that.no_certificate = true;
          that.sendconfirmDialog = true;
        } else {
          if (temp.templateImage) {
            //user have already separately edited templates
            that.previewImage = temp.templateImage ?? "";
          } else {
            //user don't have own templates, so use frome the one in the table..
            let isForPreview = true;
            certificateJS
              .addTextToImageAndPdf(
                item.fullName,
                certi_Item,
                "image",
                isForPreview
              )
              .then((imageUrl) => {
                that.previewImage = imageUrl;
              });
          }
          that.previewimg = true;
        }
      } catch (error) {
        console.error(error);
      }
    },

    async SendCertConfirmDialog(val) {
      let that = this;
      try {
        await axios
          .get(
            `${that.web_url}Certificate/GetCertificatePerUserByQuizID?QuizID=` +
              val.quizID +
              `&UserID=` +
              val.userID
          )
          .then(function (res) {
            if (res.data.status === 0) {
              if (
                res.data.data.certificatePerUserID === null &&
                that.certificateItems.length === 0
              ) {
                that.no_certificate = true;
              } else {
                that.no_certificate = false;
                that.hascertificatebyoneflag = true;
                // that.SentCertificatePerOneObject = res.data.data;
              }
              that.sendconfirmDialog = true;
              that.fullName = val.fullName;
              that.listOfGraduateObject = res.data.data.certificatePerUserID
                ? { ...val, ...res.data.data }
                : val;

              that.emailNameList.push({
                fullName: val.fullName,
                userID: val.userID,
              });
            }
          });
      } catch (error) {
        alert(error);
      }
    },

    sendMailByList() {
      if (this.certificateItems.length === 0) {
        this.no_certificate = true;
        this.sendconfirmDialog = true;
      } else {
        this.sendmailListDialog = true;
        this.no_certificate = false;
        this.sendconfirmDialog = false;
      }
    },

    async PrepareImageAndPdfEmailTemplate() {
      let tempsImg = [];
      let tempsPdf = [];
      let certiItem = this.certificateItems[0] ?? null;
      let isForPreview = false;

      // If there are no users, return empty array
      if (!this.emailNameList || this.emailNameList.length === 0) {
        return [];
      }

      for (let n of this.emailNameList) {
        try {
          // Generate PDF
          const PdfBlobUrl = await certificateJS.addTextToImageAndPdf(
            n.fullName,
            certiItem,
            "pdf",
            this.web_url,
            isForPreview
          );

          // Generate Image
          const base64ImageUrl = await certificateJS.addTextToImageAndPdf(
            n.fullName,
            certiItem,
            "image",
            isForPreview
          );

          tempsImg.push(base64ImageUrl);
          tempsPdf.push(PdfBlobUrl);
        } catch (error) {
          console.error(`Error processing ${n.fullName}:`, error);
        }
      }
      const formDataTemp = new FormData();

      tempsPdf.forEach((pdfPart, index) => {
        formDataTemp.append(
          `pdf`,
          pdfPart,
          `certificate_${index}_${Date.now()}.pdf`
        );
      });

      tempsImg.forEach((imgPart, index) => {
        formDataTemp.append(
          `image`,
          imgPart,
          `certificate_${index}_${Date.now()}.png`
        );
      });
      const uploadedTemps = await this.uploadFilesArray(formDataTemp);

      return uploadedTemps;
    },

    async Send_CertificatePerOne() {
      var that = this;
      that.sendconfirmDialog = false;
      const templates = that.listOfGraduateObject.userTemplateImage
        ? null
        : (await this.PrepareImageAndPdfEmailTemplate()) || null;
      let imgTemp = templates?.uploadedImageUrl[0] ?? null;
      let pdfTemp = templates?.uploadedPdfUrl[0] ?? null;

      let request = {};
      if (that.hascertificatebyoneflag) {
        request = {
          quizID: that.QuizIdLocal,
          certificateID: that.certificateItems[0]?.certificateID,
          userList: [
            {
              userID: that.listOfGraduateObject.userID,
              fullName: that.listOfGraduateObject.fullName,
              email: that.listOfGraduateObject.email,
              courseID: that.listOfGraduateObject.courseID,
              templateImage: imgTemp
                ? imgTemp
                : certificateJS.removeToken(
                    that.listOfGraduateObject.templateImage
                  ),
              templatePDF: pdfTemp
                ? pdfTemp
                : certificateJS.removeToken(
                    that.listOfGraduateObject.templatePDF
                  ),
            },
          ],
          createBy: that.memberid,
          companyID: localStorage.getItem("companyID"),
        };

        that.SentCertificateManual(request);
      }
      // else if (
      //   that.certificateItems.length != 0 &&
      //   that.certificateItems[0].status == true
      // ) {
      //   request = {
      //     quizID: that.QuizIdLocal,
      //         certificateID: templates[0]?.certificateID,
      //     userList: [
      //       {
      //         userID: that.listOfGraduateObject.userID,
      //         fullName: that.listOfGraduateObject.fullName,
      //         email: that.listOfGraduateObject.email,
      //         courseID: that.listOfGraduateObject.courseID,
      //         templateImage: templates[0]?.image,
      //         templatePDF: templates[0]?.pdf,
      //       },
      //     ],
      //     createBy: that.memberid,
      //     companyID: localStorage.getItem("companyID"),
      //   };
      //   //that.SentCertificateManual(request);
      // }
      else {
        alert(
          "Please add certificate first or your certificate's status is inactive!"
        );
      }
    },

    async Send_Certificate(items) {
      this.emailNameList = items
        .filter((v) => v.userTemplatePDF === null)
        .map((x) => ({
          fullName: x.fullName,
          userID: x.userID,
        }));
      const templates = await this.PrepareImageAndPdfEmailTemplate();

      if (items.length == 0) {
        alert("Please add users to send certificate!");
      } else {
        let filterdata = items.map((item, index) => {
          const templateImg = templates?.uploadedImageUrl[index] || null;
          const templatePdf = templates?.uploadedPdfUrl[index] || null;

          return {
            fullName: item.fullName,
            userID: item.userID,
            email: item.email,
            courseID: item.courseID,
            templateImage:
              templateImg ?? certificateJS.removeToken(item.userTemplateImage),
            templatePDF:
              templatePdf ?? certificateJS.removeToken(item.userTemplatePDF),
          };
        });

        let request = {
          quizID: this.QuizIdLocal,
          certificateID: this.certificateItems[0]?.certificateID,
          userList: filterdata,
          createBy: this.memberid,
          companyID: localStorage.getItem("companyID"),
        };

        this.SentCertificateManual(request);
      }
      this.sendmailListDialog = false;
    },

    // Helper method for uploading PDF files
    uploadFilesArray(data) {
      return axios
        .post(`${this.web_url}File/UploadPDFFileV4`, data)
        .then((response) => {
          if (response.data.status === 0) {
            return response.data.data;
          }

          return "";
        })
        .catch((error) => {
          console.error("File upload error: ", error);
          return "";
        });
    },

    Show_DeleteCertDialog(item) {
      let that = this;
      that.certificateID = item.certificateID;
      that.deleteCertDialog = true;
    },
    async Delete_Certificate() {
      let that = this;
      await axios
        .delete(
          `${that.web_url}Certificate/DeleteCertificate?id=` +
            that.certificateID
        )
        .then(function (res) {
          if (res.data.status == 0) {
            that.deleteCertDialog = false;
            that.certificateItems = [];
          }
        });
    },

    OpenAddCertDialog() {
      let that = this;
      //that.addCertDialog = true;
      that.certificateTitle = "addcertificate";
      that.showCertificateDialog = true;
      const temp = {
        type: 1,
        memberid: that.memberid,
        courseId: that.courseId,
        create_by: that.createdBy,
        course_name: that.courseOfQuiz[0]?.courseName
          ? that.courseOfQuiz[0]?.courseName
          : null,
        certificate_Item: null,
      };
      that.requiredDataForCert = temp;
    },

    Edit_Certificate(item) {
      this.certificateTitle = "editcertificate";
      this.showCertificateDialog = true;
      const temp = {
        type: 2,
        memberid: this.memberid,
        courseId: this.courseId,
        create_by: this.createdBy,
        course_name: this.courseOfQuiz[0]?.courseName
          ? this.courseOfQuiz[0]?.courseName
          : null,
        certificate_Item: item,
      };
      this.requiredDataForCert = temp;
    },

    Close_SendmailListDialog() {
      let that = this;
      that.sendmailListDialog = false;
      that.sendEmailList = [];
    },
    removefromSendMailList(item, index) {
      console.log(item);
      this.sendEmailList.splice(index, 1);
    },

    async UpdateCertStatus(item) {
      let that = this;
      let request = {
        certificateID: item.certificateID,
        status: item.status,
      };
      await axios
        .post(`${that.web_url}Certificate/UpdateCertStatus`, request)
        .then(function (res) {
          if (res.data.status == 0) {
            that.GetCertificateByQuizID();
          }
        });
    },
    async UpdateCertAutoMailSendStatus(item) {
      let that = this;
      let request = {
        certificateID: item.certificateID,
        emailAutoSend: item.emailAutoSend,
      };

      await axios
        .post(
          `${that.web_url}Certificate/UpdateCertMailAutoSendStatus`,
          request
        )
        .then(function (res) {
          if (res.data.status == 0) {
            that.GetCertificateByQuizID();
          }
        });
    },

    async ClickHistoryIcon(item) {
      let that = this;
      let request = {
        quizID: item.quizID,
        userID: item.userID,
        companyID: localStorage.getItem("companyID"),
      };
      await axios
        .post(`${that.web_url}Certificate/GetCertificateSentRecord`, request)
        .then(function (res) {
          if (res.data.status == 0) {
            that.historyDialog = true;
            that.historyItems = res.data.data.map((v, i) => ({
              ...v,
              no: i + 1,
              date: moment(v.createDate).local().format("DD/MM/YYYY"),
              time: moment(v.createDate).local().format("hh:mm A"),
            }));
          }
        });
    },
    async SentCertificateManual(request) {
      let that = this;
      that.loadingforsentmail = true;
      await axios
        .post(`${that.web_url}Certificate/SentEmailForCertificate`, request)
        .then(function (res) {
          if (res.data.status == 0) {
            that.sendEmailList = [];
            that.GetListOfGraduates();
            that.GetCertificateByQuizID();
            alert("Mail sent successfully!");
          }
          that.selectedCerti_Item = {};
          that.loadingforsentmail = false;
        });
    },
  },
};
</script>

<style scoped>
.tooltip .tooltipText {
  visibility: hidden;
  width: 180px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  transition: opacity 0.3s;
}

.tooltip .tooltipText::after {
  content: " ";
  position: absolute;
  top: 10%;
  right: 100%; /* To the left of the tooltip */
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent black transparent transparent;
}

.tooltip:hover .tooltipText {
  visibility: visible;
}

.radio-group {
  display: flex;
  align-items: left;
  margin-left: 25px;
}

.radio-wrapper {
  position: relative;
  width: 22px;
  height: 22px;
}

.radio-placeholder,
.radio-button {
  position: absolute;
  top: 2px;

  left: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.radio-placeholder {
  background-color: #c0c0c0;
  opacity: 1;
  pointer-events: none; /* Prevent interaction */
}
.radiobutton {
  margin-top: -10px;
}
.status_list {
  border-radius: 6px;
  font-size: 14px;
  text-align: center;
  padding: 3px;
  margin: 5px 40px 0px 0px;
  background: #6fdc42;
  color: #ffffff;
  display: inline-table;
}
.status_list1 {
  border-radius: 6px;
  font-size: 14px;
  text-align: center;
  padding: 3px;
  margin: 5px 40px 0px 0px;
  background: rgb(239, 4, 4);
  color: #ffffff;
  display: inline-table;
}
.deletestatus {
  border-radius: 6px;
  font-size: 13px;
  text-align: center;
  padding: 2px;
  margin: 5px 40px 0px 0px;
  background: #ff6060;
  color: #ffffff;
  display: inline-table;
}
.passingScore {
  font-weight: bold;
  font-size: 13px;
  color: #ff6060;
}
.red-border {
  border: 1px solid #ff6060;
}
.font-style {
  font-size: 16px;
  color: #424242;
}
::v-deep .v-tab--active {
  background-color: #ffffff !important;
  color: #a6cc39 !important;
}
::v-deep .btnStyle {
  font-size: 16px;
  height: 40px;
  color: #ffffff;
  border-radius: 8px;
}
::v-deep .btnStyle2 .v-btn__content {
  font-size: 16px;
  color: #fff;
}
.vue-js-switch {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  user-select: none;
  font-size: 15px;
  cursor: pointer;
  margin-top: 13px;
}
::v-deep .limited-submit.v-text-field.v-text-field--solo .v-input__control {
  min-height: 27px;
  padding: 0;
}
::v-deep .pre-fix .v-input__slot {
  /* border: 0.5px solid #c0c0c0; */
  font-size: 16px !important;
  border-radius: 8px !important;
  box-shadow: unset !important;
}

/* table related */
::v-deep .editquiz-table tr:nth-child(even) {
  background: #e0e0e0;
  margin-top: 0.5rem;
}
::v-deep .editquiz-table .v-input--selection-controls__input .v-icon {
  color: #a6cc39 !important;
  border-radius: 4px;
  opacity: 1;
}
::v-deep .theme--light.v-label {
  color: #47484b !important;
  font-size: 14px !important;
}
::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td {
  font-size: 14px !important;
  color: #424242 !important;
  opacity: 1;
}
::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  font-size: 14px !important;
  color: #424242 !important;
  opacity: 1;
  text-align: center;
  background-color: #e0e0e0 !important;
  border-color: #e0e0e0 !important;
}
::v-deep .editquiz-table.v-data-table {
  max-width: 100%;
}
.smallBtn.v-btn:not(.v-btn--round).v-size--default {
  height: 36px;
  min-width: unset;
  padding: 0 12px;
  border-radius: 5px;
}
</style>

<style scoped>
.certTableImg {
  margin: 10px 0px;
  border-radius: 5px;
  /* height: 285px;
  width: 175px !important; */
}

.onoffswitch {
  position: relative;
  width: 73px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.onoffswitch-checkbox {
  display: none;
}

.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border-radius: 20px;
}

.onoffswitch-inner {
  width: 200%;
  margin-left: -100%;
  -moz-transition: margin 0.3s ease-in 0s;
  -webkit-transition: margin 0.3s ease-in 0s;
  -o-transition: margin 0.3s ease-in 0s;
  transition: margin 0.3s ease-in 0s;
}

.onoffswitch-inner:before,
.onoffswitch-inner:after {
  float: left;
  width: 50%;
  height: 28px;
  padding: 0;
  line-height: 30px;
  font-size: 14px;
  color: white;
  font-family: Trebuchet, Arial, sans-serif;
  font-weight: bold;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.onoffswitch-inner:before {
  content: "ON";
  padding-left: 11px;
  background-color: #6fdc42;
  color: #ffffff;
}

.onoffswitch-inner:after {
  content: "OFF";
  padding-right: 11px;
  color: #ffffff;
  background-color: #707070;
  opacity: 1;
  text-align: right;
}

.onoffswitch-switch {
  width: 26px;
  height: 26px;
  margin: 1px;
  background: #ffffff;
  border-radius: 20px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 44px;
  -moz-transition: all 0.3s ease-in 0s;
  -webkit-transition: all 0.3s ease-in 0s;
  -o-transition: all 0.3s ease-in 0s;
  transition: all 0.3s ease-in 0s;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
  right: 0px;
}
::v-deep .v-dialog {
  border-radius: 10px !important;
}
.font-style {
  font-size: 16px;
  color: #4d4f5c;
}
.font-styleDialog {
  font-size: 20px;
  color: #4d4f5c;
}
.btnfont-style {
  font-size: 20px;
}

::v-deep .primary {
  color: #000000 !important;
  background-color: #a6cc39 !important;
  opacity: 1;
}

::v-deep .primary--text {
  color: #a6cc39 !important;
  caret-color: #a6cc39 !important ;
}
::v-deep .Image_Btn.v-btn--absolute.v-btn--right,
.v-btn--fixed.v-btn--right {
  right: -10px;
}
::v-deep .Image_Btn.v-btn--fab.v-size--x-small {
  height: 25px;
  width: 25px;
}
::v-deep .Image_Btn.v-btn--fab.v-size--x-small.v-btn--absolute.v-btn--top {
  top: -12px;
}
</style>

<style scoped>
::v-deep
  .field_Style.theme--light.v-text-field
  > .v-input__control
  > .v-input__slot:before {
  border-color: transparent;
}
::v-deep .field_Style.v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}
::v-deep .field_Style.v-input__slot {
  width: 98% !important;
}
::v-deep .field_Style.v-text-field > .v-input__control > .v-input__slot:before,
.v-text-field > .v-input__control > .v-input__slot:after {
  width: 98%;
}
::v-deep
  .field_Style.theme--light.v-text-field
  > .v-input__control
  > .v-input__slot:before {
  border-color: transparent;
  border-style: none !important;
}
::v-deep .option_sheet.v-list-item-group .v-list-item--active {
  color: #ffff;
}
::v-deep .field_Style_1.primary--text {
  color: red !important;
  caret-color: red !important ;
}
::v-deep .field_Style_1.v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}
::v-deep .field_Style_1.v-input__slot {
  width: 98% !important;
}
::v-deep
  .field_Style_1.theme--light.v-text-field
  > .v-input__control
  > .v-input__slot:before {
  border-color: red;
  border-style: 1px solid !important;
  border-width: thin !important;
}
::v-deep .mdi-alert::before {
  color: red;
}

::v-deep .quiz-name .v-input__control {
  align-content: center;
  margin-left: 9px;
}
::v-deep .pagination .primary {
  background-color: #a6cc39 !important;
  color: #ffffff !important;
}
::v-deep .tableRadioBtn.v-input--selection-controls {
  margin-top: 0px;
}
::v-deep
  .v-input.v-input--is-label-active.v-input--is-dirty.v-input--is-disabled.theme--light.v-input--selection-controls.v-input--checkbox {
  color: #a6cc39;
}
</style>

<style scoped>
.btn_hover_effect,
.reset-btn {
  color: #fff;
  /* width: 80%; */
  font-size: 18px;
  z-index: 0;
  position: relative;
  display: inline-block;
}
.btn_hover_effect:before,
.reset-btn:before {
  background-color: #4fb14e;
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  opacity: 0.5 !important;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}
.btn_hover_effect:hover:before,
.reset-btn:hover:before {
  background-color: #4fb14e;
  top: 0;
}
.btn_hover_effect:hover,
.reset-btn:hover {
  transition: 0.25s;
}
.btn_hover_effect:after,
.reset-btn:after {
  position: absolute;
  right: 2.34375rem;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(50%);
  -ms-transform: translateY(-50%) translateX(50%);
  transform: translateY(-50%) translateX(50%);
}
.v-sheet.v-card {
  border-radius: 10px;
}
/* ::v-deep .v-text-field--outlined > .v-input__control > .v-input__slot {
  border: 1px solid #c0c0c0;
  border-radius: 8px;
} */
::v-deep
  .quiz_select.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  box-shadow: unset;
  border: 1px solid #707070;
  border-radius: 7px;
}
.btnStyleSubmit {
  font-size: 16px;
  color: #ffffff;
  border: 1px solid #a6cc39;
  border-radius: 8px;
}
.btnStyleCancel {
  font-size: 16px;
  color: #424242;
  border: 1px solid #c0c0c0;
  border-radius: 8px;
}
::v-deep .v-data-table thead th:first-child {
  border-radius: 8px 0 0 8px;
} /**.child_table */
::v-deep .v-data-table thead th:last-child {
  border-radius: 0 8px 8px 0;
}
@media (min-width: 1264px) {
  .container {
    max-width: unset;
  }
}
@media (min-width: 960px) {
  .container {
    max-width: unset;
  }
}
</style>

<style scoped>
::v-deep .theme--light.v-btn.v-btn--disabled {
  background-color: #cccccc !important;
  color: #ffffff !important;
}
::v-deep
  .settingTextBox.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  box-shadow: unset !important;
  font-size: 16px;
  min-width: 70px !important;
  min-height: 32px;
  border: 1px solid #c0c0c0;
}
.settingTabSameText {
  font-size: 16px;
  margin-left: 10px;
  color: #424242;
  margin-bottom: 0;
}
</style>
